import { useFetchAccountUsersQuery } from 'api/accountUser';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';
import { selectCurrentUser } from 'redux/reducers/auth';
import { useAppSelector } from 'redux/store';
import {
  SettingsUsersPermissionsTooltip,
  SettingsUsersRow,
  SettingsUsersTopBar,
} from './components';

function SettingsUsers() {
  const { t } = useTranslation();
  const currentUser = useAppSelector(selectCurrentUser);

  const { isLoading, error, data: accountUsers } = useFetchAccountUsersQuery();

  if (isLoading) return <Loader />;

  if (error) return <div>There was an error: {JSON.stringify(error)}</div>;

  if (!accountUsers || !currentUser) return null;

  return (
    <>
      <div className="bg-white max-w-100 mx-auto">
        <div>
          <SettingsUsersTopBar />
        </div>
        <div className="px-2 py-6">
          {!!accountUsers.length ? (
            <div className="flex flex-col">
              <div className="flex">
                <div className="flex-grow">
                  <span className="text-14 text-grey5 leading-20 font-bold">
                    {t('settings.users.table.name')}
                  </span>
                </div>
                {currentUser?.role === 'admin' && (
                  <div className="flex-shrink-0">
                    <div className="w-21">
                      <span className="flex items-center text-14 text-grey5 leading-20 font-bold">
                        {t('settings.users.table.permissions')}
                        <div className="ml-0.5 bump-down-1">
                          <SettingsUsersPermissionsTooltip />
                        </div>
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div>
                {accountUsers.map((accountUser) => (
                  <SettingsUsersRow
                    key={accountUser.id}
                    currentUser={currentUser}
                    accountUser={accountUser}
                  />
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default SettingsUsers;
