import { useTranslation } from 'react-i18next';
import Form from 'components/Form';
import { addHours } from 'date-fns';

interface Props {
  value?: number | null;
  errors?: string[];
  onChange: (value: number | null) => void;
}

export default function Expiration({ value, onChange }: Props) {
  const { t } = useTranslation();

  const toggle = value
    ? () => onChange(null)
    : () => onChange(addHours(new Date(), 1).getTime());
  return (
    <div className="pb-6">
      <div className="h5 mb-1 last:mb-0">
        {t('ugcRequestBuilder.form.expiresAt.label')}
      </div>

      <div className="flex items-center mb-1">
        <Form.Checkbox checked={!!value} onChange={toggle} />
        <label className="ml-1 text-dark" onClick={toggle}>
          {t('ugcRequestBuilder.form.expiresAt.toggle')}
        </label>
      </div>

      {!!value && <Form.DateTimePicker value={value} onChange={onChange} />}
    </div>
  );
}
