import { configureStore } from '@reduxjs/toolkit';
import api from 'api';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import authReducer from './reducers/auth';
import planReducer from './reducers/plan';
import errorsReducer from './reducers/error';
import submissionsReducer from './reducers/submissions';
import loadingReducer from './reducers/loading';
import uiReducer from './reducers/ui';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    errors: errorsReducer,
    submissions: submissionsReducer,
    loading: loadingReducer,
    plan: planReducer,
    ui: uiReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
