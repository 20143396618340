import { useHistory } from 'react-router-dom';
import { useCreateUgcRequestMutation } from 'api/ugcRequests';
import { useTranslation } from 'react-i18next';
import routeFor from 'helpers/routeFor';
import routes from 'helpers/routes';
import useFlashMessages from './useFlashMessages';

export default function useCreateUgcRequest() {
  const history = useHistory();
  const { addFlashMessage } = useFlashMessages();
  const { t } = useTranslation();
  const [createUgcRequest, { isLoading }] = useCreateUgcRequestMutation();

  const handleCreate = async () => {
    const result = await createUgcRequest({});

    if ('data' in result) {
      if ('errors' in result.data) {
        addFlashMessage(t('ugcRequestError.500'), { isError: true });
      } else {
        // Navigate to the build request page with the new UGC Request ID
        history.push(routeFor(routes.requests.build, 'draft', result.data.id));
      }
    } else {
      addFlashMessage(t('errorPage.heading'), { isError: true });
    }
  };

  return { handleCreate, isLoading };
}
