import cx from 'classnames';
import Form from 'components/Form';

interface Props {
  checked: boolean;
  onChange: () => void;
  isSelectMode: boolean;
}

export default function SubmissionThumbnailCheckbox(props: Props) {
  const { checked, onChange, isSelectMode } = props;

  return (
    <div
      className={cx('absolute top-1 left-1 z-10 hover:opacity-100', {
        'opacity-0 group-hover:opacity-50 hover:!opacity-100':
          !checked && !isSelectMode,
        'opacity-50 group-hover:opacity-100': !checked && isSelectMode,
        'pointer-events-none': isSelectMode,
      })}
    >
      <div className="hidden group-hover:block">
        <Form.Checkbox
          variant="large"
          checked={checked}
          onChange={onChange}
          uncheckedStyle={'mono-check'}
        />
      </div>

      <div className="block group-hover:hidden">
        <Form.Checkbox
          variant="large"
          checked={checked}
          onChange={onChange}
          uncheckedStyle={'border'}
        />
      </div>
    </div>
  );
}
