import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import AuthPage from 'components/AuthPage';
import Form from 'components/Form';
import RecoveryCodeLogIn from './RecoveryCodeLogIn';
import { useValidateOtpMutation } from 'api/mfa';

interface Props {
  semiLoggedInUser: { token: string };
}

export default function MfaLogIn({ semiLoggedInUser }: Props) {
  const { token } = semiLoggedInUser;
  const [validateOtp, { data, isLoading, error }] = useValidateOtpMutation();
  const [isUsingRecoveryCode, setIsUsingRecoveryCode] = useState(false);

  const { t } = useTranslation();
  const [code, setCode] = useState('');

  if (isUsingRecoveryCode) {
    return (
      <RecoveryCodeLogIn
        semiLoggedInUser={semiLoggedInUser}
        onCancel={() => setIsUsingRecoveryCode(false)}
      />
    );
  }

  const handleSubmit = async () => {
    validateOtp({ token, challengeCode: code });
  };

  const hasError = !!(data && 'error' in data) || !!error;

  return (
    <AuthPage
      heading={t('auth.logIn.heading')}
      description={
        <div className="text-dark leading-tight mb-1">
          <Trans
            i18nKey="auth.mfaLogIn.label.app"
            values={{
              value: t('auth.mfaLogIn.label.authenticatorApp'),
            }}
            components={{ bold: <strong /> }}
          />
        </div>
      }
    >
      <Form onSubmit={handleSubmit}>
        <Form.TextInput
          name="code"
          value={code}
          label={t('auth.mfaLogin.label')}
          onChange={(e) => setCode(e.target.value)}
          placeholder={t('auth.mfaLogIn.placeholder')}
          errors={hasError ? t('auth.mfaLogIn.invalidCode') : null}
        />

        <button
          type="submit"
          className="btn btn--primary mb-3"
          disabled={isLoading || !code}
          data-loading={isLoading}
        >
          {t('auth.mfaLogIn.submit')}
        </button>
      </Form>

      <div className="flex items-center justify-center">
        <button
          onClick={() => setIsUsingRecoveryCode(true)}
          className="text-brandPrimary hover:underline"
        >
          {t('auth.mfaLogIn.useRecoveryCode')}
        </button>
      </div>
    </AuthPage>
  );
}
