import { useDeleteAccountUserMutation } from 'api/accountUser';
import Modal from 'components/Modal';
import useFlashMessages from 'hooks/useFlashMessages';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AccountUser from 'types/AccountUser';

interface Props {
  accountUser: AccountUser;
}

export default function SettingsUsersRemoveUser(props: Props) {
  const { accountUser } = props;
  const { t } = useTranslation();
  const { addFlashMessage } = useFlashMessages();
  const [isOpen, setIsOpen] = useState(false);
  const [deleteAccountUser, { isLoading }] = useDeleteAccountUserMutation();

  const handleDelete = async () => {
    const result = await deleteAccountUser({ id: accountUser.id });
    if ('error' in result) {
      addFlashMessage(t('global.unexpectedError'), { isError: true });
    } else {
      addFlashMessage(t('settings.users.remove.success'));
      setIsOpen(false);
    }
  };
  return (
    <>
      <button
        className="ml-1 underline hover:text-grey4 disabled:opacity-50"
        onClick={() => setIsOpen(true)}
        disabled={isLoading}
      >
        {t('settings.users.remove.button')}
      </button>

      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        renderHeading={t('settings.users.remove.modalHeading')}
        renderActions={
          <>
            <button className="btn btn--grey" onClick={() => setIsOpen(false)}>
              {t('global.cancel')}
            </button>

            <button
              className="btn btn--primary"
              onClick={handleDelete}
              data-loading={isLoading}
              disabled={isLoading}
            >
              {t('settings.users.remove.confirm')}
            </button>
          </>
        }
      >
        {t('settings.users.remove.modalBody', {
          email: accountUser.user.email,
        })}
      </Modal>
    </>
  );
}
