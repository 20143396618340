import CollapsibleSection from 'components/CollapsibleSection';
import CopyButton from 'components/CopyButton';
import { useTranslation } from 'react-i18next';
import Submission from 'types/Submission';

interface Props {
  submission: Submission;
}

export default function SubmissionSidebarDataCapture(props: Props) {
  const { submission } = props;
  const dataCapture = submission.dataCapture;
  const { t } = useTranslation();
  if (!dataCapture) return null;

  return (
    <div className="mt-4">
      <CollapsibleSection
        title={t('ugcRequestShow.submissions.sidebar.dataCapture')}
      >
        {Object.keys(dataCapture).map((key) => {
          if (!dataCapture[key]) return null;

          return (
            <div className="mb-1 justify-between text-12 text-dark" key={key}>
              <div className="flex items-start">
                <span className="font-semibold">{key}</span>
                <div className="ml-0.5 w-1.5 h-1.5 pt-px">
                  <CopyButton
                    value={dataCapture[key]}
                    className="text-bodyText hover:text-dark"
                  />
                </div>
              </div>
              <div>{dataCapture[key]}</div>
            </div>
          );
        })}
      </CollapsibleSection>
    </div>
  );
}
