import { useBulkDownloadSubmissionsMutation } from 'api/submissions';
import useFlashMessages from 'hooks/useFlashMessages';
import { useSocket, useSocketEvent } from 'hooks/useSocket';
import { IconDownload, IconLoader } from 'icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Submission from 'types/Submission';
import SubmissionBulkAction from './SubmissionBulkAction';

interface Props {
  submissions: Submission[];
}

export default function SubmissionBulkDownload(props: Props) {
  const { submissions } = props;
  const { t } = useTranslation();
  const { socketId } = useSocket();
  const { addFlashMessage } = useFlashMessages();
  const [bulkDownloadUuid, setBulkDownloadUuid] = useState<string | null>(null);
  const [bulkDownloadSubmissions, { isLoading: isStartingDownload }] =
    useBulkDownloadSubmissionsMutation();

  useSocketEvent(
    'submissionZipReady',
    (params: { uuid: string; url: string }) => {
      if (params.uuid === bulkDownloadUuid) setBulkDownloadUuid(null);
      window.location.href = params.url;
    }
  );

  const handleDownload = async () => {
    const ids = submissions.map((f) => f.id);
    const result = await bulkDownloadSubmissions({ ids, socketId });
    if ('error' in result) {
      addFlashMessage(t('global.unexpectedError'), { isError: true });
    } else {
      setBulkDownloadUuid(result.data.uuid);
    }
  };

  const isLoading = isStartingDownload || !!bulkDownloadUuid;

  return (
    <SubmissionBulkAction
      title={
        isLoading
          ? t('ugcRequestShow.submissions.bulkActions.preparingDownload')
          : t('ugcRequestShow.submissions.bulkActions.download')
      }
      icon={isLoading ? IconLoader : IconDownload}
      onClick={handleDownload}
    />
  );
}
