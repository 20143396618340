import { useBulkUpdateSubmissionsMutation } from 'api/submissions';
import SubmissionDeleteModal from 'components/SubmissionDeleteModal';
import RecordCount from 'components/RecordCount';
import { useSelectableContext } from 'hooks/useSelectable';
import { IconThumbsDownEmpty, IconThumbsUpEmpty, IconTrash } from 'icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSubmissionsById } from 'redux/reducers/submissions';
import { useAppSelector } from 'redux/store';
import UgcRequest from 'types/UgcRequest';
import Submission from 'types/Submission';
import { SubmissionBulkAction, SubmissionBulkDownload } from './components';

interface Props {
  totalCount: number;
  loadedCount: number;
  ugcRequest: UgcRequest;
}

export default function SubmissionBulkActions(props: Props) {
  const { totalCount, loadedCount, ugcRequest } = props;
  const { t } = useTranslation();
  const { selectAll, selectNone, selectedIds } = useSelectableContext();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const selectedSubmissions = useAppSelector((state) =>
    getSubmissionsById(state, selectedIds as number[])
  );
  const [bulkUpdateSubmissions] = useBulkUpdateSubmissionsMutation();

  const rateSubmissions = (rating: Submission['rating']) => {
    bulkUpdateSubmissions({
      submissions: selectedSubmissions,
      changes: { rating },
    });
  };

  return (
    <>
      <div className="flex items-center h-5">
        <div className="mr-1">
          <RecordCount
            msgPrefix="ugcRequestShow.submissions.count"
            totalCount={totalCount}
            loadedCount={loadedCount}
            selectAll={selectAll}
            selectNone={selectNone}
            selectedCount={selectedIds.length}
          />
        </div>

        {!!selectedIds.length && (
          <>
            <SubmissionBulkAction
              title={t('ugcRequestShow.submissions.bulkActions.star')}
              icon={IconThumbsUpEmpty}
              onClick={() => rateSubmissions('starred')}
            />

            <SubmissionBulkAction
              title={t('ugcRequestShow.submissions.bulkActions.reject')}
              icon={IconThumbsDownEmpty}
              onClick={() => rateSubmissions('rejected')}
            />

            <SubmissionBulkDownload submissions={selectedSubmissions} />

            <SubmissionBulkAction
              title={t('ugcRequestShow.submissions.bulkActions.delete')}
              icon={IconTrash}
              onClick={() => setIsDeleteModalOpen(true)}
            />
          </>
        )}
      </div>

      <SubmissionDeleteModal
        ugcRequest={ugcRequest}
        submissions={selectedSubmissions}
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
      />
    </>
  );
}
