import Avatar from 'components/Avatar';
import { useTranslation } from 'react-i18next';
import AccountUser from 'types/AccountUser';
import User from 'types/User';
import SettingsUsersRemoveUser from './SettingsUsersRemoveUser';
import SettingsUsersRole from './SettingsUsersRole';

interface Props {
  accountUser: AccountUser;
  currentUser: User;
}

export default function SettingsUsersActiveRow(props: Props) {
  const { accountUser, currentUser } = props;
  const { user } = accountUser;
  const { t } = useTranslation();
  const { name, avatarUrl, email } = user;

  return (
    <div
      key={accountUser.id}
      className="py-2 flex flex-col border-0 border-b border-solid border-grey7"
    >
      <div className="flex items-center">
        <div className="flex-grow flex items-center space-x-1.5">
          <div className="w-5 h-5">
            <Avatar src={avatarUrl} name={name} />
          </div>
          <div className="flex flex-col">
            <span className="text-14 text-dark leading-20">
              {name || t('settings.users.nameNotSet')}
            </span>
            <div className="flex items-center text-14 text-grey5 leading-20">
              {email || t('settings.users.emailNotSet')}

              {currentUser.role === 'admin' &&
                accountUser.user.id !== currentUser.id && (
                  <SettingsUsersRemoveUser accountUser={accountUser} />
                )}
            </div>
          </div>
        </div>
        {currentUser.role === 'admin'}
        <div className="flex-shrink-0 ">
          <div className="w-21">
            <SettingsUsersRole
              accountUser={accountUser}
              currentUser={currentUser}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
