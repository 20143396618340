import Form from 'components/Form';
import { useTranslation } from 'react-i18next';
import DataCaptureFieldDefinition from 'types/DataCaptureFieldDefinition';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IconClose, IconDragHandle } from 'icons';

interface Props {
  field: DataCaptureFieldDefinition;
  onUpdate: (changes: Partial<DataCaptureFieldDefinition>) => void;
  onRemove: () => void;
}

export default function DataCaptureField(props: Props) {
  const { field, onUpdate, onRemove } = props;
  const { t } = useTranslation();

  const typeOptions = (['text', 'email'] as const).map((type) => ({
    value: type,
    label: t(`ugcRequestBuilder.form.dataCapture.types.${type}`),
  }));

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: field.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className="mb-2 rounded-lg bg-grey9 p-2 pt-3.5 relative"
      ref={setNodeRef}
      style={style}
      {...attributes}
      role={undefined}
    >
      <button
        type="button"
        className="w-2.5 h-2.5 absolute top-0.5 left-0.5 text-light hover:text-dark"
        style={{ cursor: 'grab' }}
        {...listeners}
        aria-label={t('global.beginDrag')}
      >
        <IconDragHandle className="w-full h-full" />
      </button>

      <button
        type="button"
        className="w-2 h-2 absolute top-1 right-1 text-light hover:text-dark"
        onClick={onRemove}
        aria-label={t('global.delete')}
      >
        <IconClose className="w-full h-full" />
      </button>

      <Form.TextInput
        label={t('ugcRequestBuilder.form.dataCapture.label.label')}
        value={field.label}
        name="label"
        onChange={(e) => onUpdate({ label: e.target.value })}
      />

      <div className="flex items-start">
        <div className="flex-1 mr-3">
          <Form.Select
            label={t('ugcRequestBuilder.form.dataCapture.type.label')}
            options={typeOptions}
            name="type"
            value={typeOptions.find((o) => o.value === field.type)}
            onChange={(type) =>
              onUpdate({
                type: (type?.value ||
                  'text') as DataCaptureFieldDefinition['type'],
              })
            }
          />
        </div>

        <Form.Field
          label={t('ugcRequestBuilder.form.dataCapture.required.label')}
        >
          <div className="flex items-center pt-1">
            <Form.Checkbox
              checked={field.required}
              variant="large"
              onChange={() => onUpdate({ required: !field.required })}
            />
          </div>
        </Form.Field>
      </div>
    </div>
  );
}
