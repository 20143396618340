import api from './index';
import camelize from 'camelize';
import AccountUser from 'types/AccountUser';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchAccountUsers: builder.query<AccountUser[], void>({
      query: () => ({ url: 'account/users' }),
      transformResponse: camelize,
      providesTags: (result, error, arg) =>
        result?.length
          ? result.map((accountUser) => ({
              type: 'AccountUser',
              id: accountUser.id,
            }))
          : ['AccountUser'],
    }),

    updateAccountUser: builder.mutation<
      AccountUser,
      { id: number; role: string }
    >({
      query: ({ id, role }) => ({
        url: `account/users/${id}`,
        method: 'PUT',
        body: { role },
      }),
      transformResponse: camelize,
      invalidatesTags: (result, error, arg) => [
        { type: 'AccountUser', id: arg.id },
      ],
    }),

    deleteAccountUser: builder.mutation<{ success: true }, { id: number }>({
      query: ({ id }) => ({
        url: `account/users/${id}`,
        method: 'DELETE',
      }),
      transformResponse: camelize,
      invalidatesTags: (result, error, arg) => [
        { type: 'AccountUser', id: arg.id },
        'Plan',
      ],
    }),
  }),
});

export default extendedApi;

export const {
  useFetchAccountUsersQuery,
  useDeleteAccountUserMutation,
  useUpdateAccountUserMutation,
} = extendedApi;
