import UgcRequest from 'types/UgcRequest';
import UgcRequestParams from 'types/UgcRequestParams';
import UgcRequestType from 'types/UgcRequestType';
import api from './index';
import camelize from 'camelize';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchUgcRequest: builder.query<UgcRequest, { id: number | string }>({
      query: (params) => ({
        url: `requests/${params.id}`,
        method: 'GET',
      }),
      transformResponse: camelize,
      providesTags: (result, error, arg) => [
        { type: 'UgcRequest', id: arg.id },
      ],
    }),

    fetchUgcRequests: builder.query<UgcRequest[], { type?: UgcRequestType }>({
      query: ({ type }) => ({
        url: `requests?type=${type || ''}`,
        method: 'GET',
      }),
      transformResponse: camelize,
      providesTags: ['UgcRequest'],
    }),

    createUgcRequest: builder.mutation<UgcRequest, Partial<UgcRequestParams>>({
      query: (params) => ({
        url: 'requests',
        method: 'POST',
        body: {
          ...params,
          expiresAt: params.expiresAt ? params.expiresAt / 1000 : undefined,
        },
      }),
      transformResponse: camelize,
      invalidatesTags: ['UgcRequest'],
    }),

    updateUgcRequest: builder.mutation<
      UgcRequest,
      { id: number | string; values: Partial<UgcRequestParams> }
    >({
      query: ({ id, values }) => ({
        url: `requests/${id}`,
        method: 'PUT',
        body: {
          ...values,
          expiresAt: !!values.expiresAt ? values.expiresAt / 1000 : null,
        },
      }),
      transformResponse: camelize,
      invalidatesTags: ['UgcRequest'],
    }),

    deleteUgcRequest: builder.mutation<UgcRequest, { id: number }>({
      query: ({ id }) => ({ url: `requests/${id}`, method: 'DELETE' }),
      transformResponse: camelize,
      invalidatesTags: ['UgcRequest'],
    }),

    checkUrlSlug: builder.query<
      { taken: boolean },
      { id?: number; value: string }
    >({
      query: ({ value, id }) => ({
        url: `url-slugs/check/${value}?id=${id || 0}`,
        method: 'GET',
      }),
    }),
  }),
});

export default extendedApi;

export const {
  useFetchUgcRequestQuery,
  useFetchUgcRequestsQuery,
  useLazyFetchUgcRequestQuery,
  useLazyFetchUgcRequestsQuery,
  useCreateUgcRequestMutation,
  useUpdateUgcRequestMutation,
  useDeleteUgcRequestMutation,
  useCheckUrlSlugQuery,
  useLazyCheckUrlSlugQuery,
} = extendedApi;
