import CollapsibleSection from 'components/CollapsibleSection';
import formatDuration from 'helpers/formatDuration';
import { useTranslation } from 'react-i18next';
import Submission from 'types/Submission';

interface Props {
  submission: Submission;
}

export default function SubmissionSidebarMetadata(props: Props) {
  const { submission } = props;
  const { t } = useTranslation();

  return (
    <CollapsibleSection
      title={t('ugcRequestShow.submissions.sidebar.metadata')}
    >
      <div className="flex items-center justify-between text-12 text-dark">
        <div className="flex-1 mr-2 font-semibold">
          {t('ugcRequestShow.submissions.sidebar.dimensions')}
        </div>
        <div>
          {submission.width}x{submission.height}
        </div>
      </div>

      {!!submission.videoDuration && (
        <div className="flex items-center justify-between text-12 text-dark">
          <div className="flex-1 mr-2 font-semibold">
            {t('ugcRequestShow.submissions.sidebar.duration')}
          </div>
          <div>{formatDuration(submission.videoDuration)}</div>
        </div>
      )}

      <div className="flex items-center justify-between text-12 text-dark">
        <div className="flex-1 mr-2 font-semibold">
          {t('ugcRequestShow.submissions.sidebar.contentType')}
        </div>
        <div>{submission.contentType}</div>
      </div>
    </CollapsibleSection>
  );
}
