import routeFor from 'helpers/routeFor';
import routes from 'helpers/routes';
import UgcRequest from 'types/UgcRequest';
import { Redirect } from 'react-router';
import {
  UgcRequestTopBar,
  UgcRequestEmpty,
  UgcRequestSubmissions,
} from './components';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

interface Props {
  ugcRequest?: UgcRequest;
}

export default function UgcRequestsShow(props: Props) {
  const { ugcRequest } = props;
  const { t } = useTranslation();

  if (ugcRequest?.status === 'draft') {
    return (
      <Redirect to={routeFor(routes.requests.edit, 'draft', ugcRequest.id)} />
    );
  }

  return ugcRequest ? (
    <>
      <Helmet
        title={t('ugcRequestShow.pageTitle', {
          subject: ugcRequest.subject || t('global.noSubject'),
        })}
      />

      <UgcRequestTopBar ugcRequest={ugcRequest} />
      {ugcRequest.submissionsCount === 0 ? (
        <UgcRequestEmpty ugcRequest={ugcRequest} />
      ) : (
        <UgcRequestSubmissions ugcRequest={ugcRequest} />
      )}
    </>
  ) : (
    <div className="notice notice--error">!!!</div>
  );
}
