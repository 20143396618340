import { IconTrash } from 'icons';
import React from 'react';
import Submission from 'types/Submission';
import { useState } from 'react';
import SubmissionDeleteModal from 'components/SubmissionDeleteModal';
import SubmissionRating from 'components/SubmissionRating';
import UgcRequest from 'types/UgcRequest';

interface Props {
  submission: Submission;
  ugcRequest: UgcRequest;
}

export default function SubmissionThumbnailActions(props: Props) {
  const { submission, ugcRequest } = props;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const iconClassName = 'block w-full h-full text-white';

  return (
    <>
      <div className="flex items-center space-x-1.5 absolute top-1 right-1 z-10">
        <button
          className="w-3 h-3 hidden group-hover:block"
          onClick={(e) => {
            setIsDeleteModalOpen(true);
            e.stopPropagation();
          }}
        >
          <IconTrash className={iconClassName} />
        </button>

        <SubmissionRating submission={submission} theme="thumbnail" />
      </div>

      <SubmissionDeleteModal
        ugcRequest={ugcRequest}
        submissions={[submission]}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        isOpen={isDeleteModalOpen}
      />
    </>
  );
}
