import { createSlice } from '@reduxjs/toolkit';
import Plan from 'types/Plan';
import planApi from 'api/plan';
import submissionsApi from 'api/submissions';
import subscriptionApi from 'api/subscriptions';
import { RootState } from 'redux/store';

const initialState: Plan = {
  maxRequests: 0,
  maxStorageInGb: 0,
  maxUsers: 0,
  maxUploadsPerTerm: 0,
  hideSocialieBranding: false,
  customDomains: false,
  customFont: false,
  sponsor: false,
  currentTermEnd: 0,
  currentTermStart: 0,
  status: 'active',
  isActive: true,
  usage: {
    userCount: 0,
    submissionCount: 0,
    requestCount: 0,
    storageUsed: 0,
  },
};

const { reducer } = createSlice({
  name: 'plan',
  initialState,
  reducers: {},
  extraReducers: ({ addMatcher }) => {
    addMatcher(planApi.endpoints.fetchPlan.matchFulfilled, (state, action) => {
      return action.payload;
    });

    addMatcher(
      subscriptionApi.endpoints.fetchSubscription.matchFulfilled,
      (state, action) => {
        state.isActive = action.payload.isActive;
        state.currentTermEnd = action.payload.currentTermEnd;
        state.currentTermStart = action.payload.currentTermStart;
      }
    );

    addMatcher(
      submissionsApi.endpoints.bulkDeleteSubmissions.matchFulfilled,
      (state, action) => {
        const { submissions } = action.meta.arg.originalArgs;
        const deletedSize = submissions.reduce(
          (total, fs) => total + fs.fileSize,
          0
        );
        state.usage.storageUsed = state.usage.storageUsed - deletedSize;
      }
    );
  },
});

export default reducer;

export const selectPlan = (state: RootState) => state.plan;
