import { useLazyDownloadSubmissionQuery } from 'api/submissions';
import { IconDownload } from 'icons';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Submission from 'types/Submission';

interface Props {
  submission: Submission;
}

export default function SubmissionSidebarDownload(props: Props) {
  const { submission } = props;
  const { t } = useTranslation();

  const [downloadSubmission, { isLoading: isDownloading, data }] =
    useLazyDownloadSubmissionQuery();
  const downloadUrl = data?.url || '';
  const prevDownloadUrl = useRef(downloadUrl);

  useEffect(() => {
    if (downloadUrl !== prevDownloadUrl.current) {
      prevDownloadUrl.current = downloadUrl;
      window.location.href = downloadUrl;
    }
  }, [downloadUrl]);

  return (
    <button
      className="btn btn--primary btn--small"
      onClick={() => downloadSubmission({ id: submission.id })}
      disabled={isDownloading}
      data-loading={isDownloading}
    >
      <span className="flex items-center justify-center w-full">
        <IconDownload />
        <span>{t('ugcRequestShow.submissions.sidebar.download')}</span>
      </span>
    </button>
  );
}
