import { IconTelescope } from 'icons';
import { useTranslation } from 'react-i18next';

interface Props {
  onResetFilters: () => void;
}

export default function SubmissionsNoResults({ onResetFilters }: Props) {
  const { t } = useTranslation();
  return (
    <div className="absolute-center inline-block text-center">
      <IconTelescope className="block mx-auto text-dark w-6 h-6 mb-1" />
      <div className="mb-1 text-20 text-black font-bold">
        {t('ugcRequestShow.submissions.noResults')}
      </div>

      <button
        onClick={onResetFilters}
        className="btn btn--grey btn--inlineBlock mx-auto"
      >
        {t('ugcRequestShow.submissions.resetFilters')}
      </button>
    </div>
  );
}
