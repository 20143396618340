import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal';
import UgcRequest from 'types/UgcRequest';
import { useDeleteUgcRequestMutation } from 'api/ugcRequests';
import useFlashMessages from 'hooks/useFlashMessages';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  ugcRequest: UgcRequest;
  onSuccess?: () => void;
}

export default function UgcRequestDeleteModal(props: Props) {
  const { isOpen, onRequestClose, onSuccess, ugcRequest } = props;
  const [deleteUgcRequest, { isLoading }] = useDeleteUgcRequestMutation();
  const { t } = useTranslation();
  const { addFlashMessage } = useFlashMessages();

  const handleDelete = async () => {
    const result = await deleteUgcRequest({ id: ugcRequest.id });
    if ('error' in result) {
      addFlashMessage(t('global.unexpectedError'), { isError: true });
    } else {
      addFlashMessage(t('ugcRequestDeleteModal.success'));
      onRequestClose();
      onSuccess?.();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      renderHeading={t('ugcRequestDeleteModal.heading')}
      renderActions={
        <>
          <button className="btn btn--grey" onClick={onRequestClose}>
            {t('global.cancel')}
          </button>

          <button
            className="btn btn--primary"
            disabled={isLoading}
            data-loading={isLoading}
            onClick={handleDelete}
          >
            {t('ugcRequestDeleteModal.continue')}
          </button>
        </>
      }
    >
      {t('ugcRequestDeleteModal.body')}
    </Modal>
  );
}
