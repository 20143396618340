import React, { useState, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { selectCurrentUser } from 'redux/reducers/auth';
import { useAppSelector } from 'redux/store';
import { useTranslation } from 'react-i18next';
import routes from 'helpers/routes';
import hasClass from 'helpers/hasClass';
import routeFor from 'helpers/routeFor';
import useClickOutside from 'hooks/useClickOutside';
import {
  IconBranding,
  IconGroups,
  IconSubscription,
  IconDomain,
  IconCaretLeft,
  IconCaretRight,
  IconRequest,
  IconShare,
  IconPlusV2,
  IconCaretDown,
  IconCaretUp,
} from 'icons';
import useCreateUgcRequest from 'hooks/useCreateUgcRequests';
import UserMenu from '../Layout/components/UserMenu';
import AccountsMenu from '../Layout/components/AccountsMenu';
import OverLimitWarning from '../Layout/components/OverLimitWarning';
import UpsellPromo from './GlobalSidebarNavUpsellPromo';
import Tooltip from 'components/Tooltip';
import NavItem from './components/NavItem';

interface GlobalSidebarNavProps {
  isOpen: boolean;
  handleOnOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function GlobalSidebarNav(props: GlobalSidebarNavProps) {
  const { isOpen, handleOnOpen } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const [isUGCAccordionOpen, isSetUGCAccordionOpen] = useState(true);
  const { handleCreate, isLoading } = useCreateUgcRequest();

  const sidebarWrapperRef = useRef<HTMLDivElement>(null);

  const toggleSidebar = (event: React.MouseEvent) => {
    event.stopPropagation();
    handleOnOpen((prevIsOpen) => !prevIsOpen);
  };

  const clickOut = useCallback(
    (e: any) => {
      if (!isOpen || hasClass(e.target, 'tippy-box')) return;
      handleOnOpen(false);
    },
    [isOpen, handleOnOpen]
  );

  useClickOutside(sidebarWrapperRef, clickOut);

  const currentUser = useAppSelector(selectCurrentUser);

  if (!currentUser) return null;

  return (
    <>
      <div className="relative h-full">
        <div
          ref={sidebarWrapperRef}
          className={`global-sidebar ${isOpen ? 'w-auto' : 'w-8'} `}
        >
          <nav className={`${isOpen ? 'grid-col-200px' : 'grid-col-60'} `}>
            <div
              className={`global-sidebar-nav-content self-start ${
                isOpen ? 'pr-0.5' : 'p-0'
              }`}
            >
              <div className="border-solid border-b-1 border-grey2">
                <div className="py-2 px-1.5 flex items-center">
                  <AccountsMenu
                    currentUser={currentUser}
                    showDropDownIcon={isOpen}
                  />
                  <button
                    className="global-sidebar-toggle-btn"
                    onClick={toggleSidebar}
                  >
                    {isOpen ? <IconCaretLeft /> : <IconCaretRight />}
                  </button>
                </div>
              </div>
              <div className="pt-2 pb-1">
                <div className="px-2">
                  <div
                    className={`text-white font-medium border-b-1 border-solid border-grey2 pb-1`}
                  >
                    {isOpen && (
                      <div className="flex">
                        <button
                          className="flex flex-1 items-center justify-between text-white pr-0.5"
                          onClick={(event) => {
                            event.stopPropagation();
                            isSetUGCAccordionOpen(!isUGCAccordionOpen);
                          }}
                        >
                          <span>{t('globalMenu.UGC')}</span>
                          <span className="text-grey5">
                            {isUGCAccordionOpen ? (
                              <IconCaretUp />
                            ) : (
                              <IconCaretDown />
                            )}
                          </span>
                        </button>
                      </div>
                    )}

                    {(isUGCAccordionOpen || !isOpen) && (
                      <div
                        className={`pt-1.25 grid ${
                          isOpen ? 'delay-50' : 'justify-center w-4'
                        } transition-width duration-300 ease`}
                      >
                        <Tooltip
                          theme="primaryDark"
                          arrow
                          placement="bottom"
                          content={t('globalMenu.createRequest')}
                          disabled={isOpen}
                        >
                          <button
                            onClick={handleCreate}
                            disabled={isLoading}
                            className={`${
                              location.pathname.includes('/requests/draft/')
                                ? 'bg-grey3 text-white font-medium'
                                : 'text-grey5'
                            } ${
                              isOpen ? 'w-auto justify-start' : 'justify-center'
                            } flex items-center p-0.25 h-4 w-4 rounded-lg text-14 hover:bg-grey3 mb-1 focus:outline-none focus-visible:ring focus-visible:ring-opacity-20 active:outline-none focus-within:outline-none`}
                          >
                            <div className="w-4 h-4">
                              <IconPlusV2 className="w-full h-full icon-padding" />
                            </div>
                            <span
                              className={`${
                                isOpen
                                  ? 'transition-width ease-in-out delay-150 duration-75 opacity-100 pl-1'
                                  : 'opacity-0 w-0 p-0'
                              }`}
                            >
                              {t('globalMenu.createRequest')}
                            </span>
                          </button>
                        </Tooltip>
                      </div>
                    )}
                  </div>

                  <div className="pt-2">
                    <NavItem
                      routeTo={routeFor(routes.requests.index, 'live')}
                      text={t('globalMenu.request')}
                      Icon={IconRequest}
                      isOpen={isOpen}
                    />
                    <NavItem
                      routeTo={routes.settings.brand}
                      text={t('settings.nav.brand')}
                      Icon={IconBranding}
                      isOpen={isOpen}
                    />
                    <NavItem
                      routeTo={routes.settings.users}
                      text={t('settings.nav.users')}
                      Icon={IconGroups}
                      isOpen={isOpen}
                    />
                    <NavItem
                      routeTo={routes.settings.domains.root}
                      text={t('settings.nav.domains')}
                      Icon={IconDomain}
                      isOpen={isOpen}
                    />
                    <NavItem
                      routeTo={routes.settings.subscription}
                      text={t('settings.nav.subscription')}
                      Icon={IconSubscription}
                      isOpen={isOpen}
                    />
                  </div>
                </div>
              </div>
              {isOpen && (
                <div className="py-2 px-2.5 border-t-1 border-solid border-grey2">
                  <UpsellPromo />
                  <a
                    href="https://go.photoshelter.com/brands/collaboration/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex justify-between items-center h-4 rounded-lg text-white font-medium text-14  hover:text-grey5 mb-1 -ml-0.5"
                  >
                    <span className="pr-1">{t('globalMenu.distribution')}</span>
                    <IconShare className="text-grey5 h-2 w-2" />
                  </a>

                  <a
                    href="https://go.photoshelter.com/brands/digital-asset-management/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex justify-between items-center h-4 rounded-lg text-white font-medium text-14 hover:text-grey5 mb-1 -ml-0.5"
                  >
                    <span className="pr-1">
                      {t('globalMenu.assetManagement')}
                    </span>
                    <IconShare className="text-grey5 h-2 w-2" />
                  </a>
                </div>
              )}
            </div>

            <div className="self-end px-1 py-3 border-t-1 border-solid border-grey2 -mr-0.5">
              <Tooltip
                theme="primaryDark"
                arrow
                placement="top"
                content={t('globalMenu.userMenu.account')}
                disabled={isOpen}
              >
                <div className="flex items-center pl-1">
                  <UserMenu currentUser={currentUser} />

                  {isOpen && (
                    <div className="pl-1 text-12 text-white w-16 leading-16">
                      <p className="truncate">{currentUser?.name}</p>
                      <p className="text-grey5 capitalize">
                        {currentUser?.role}
                      </p>
                    </div>
                  )}
                </div>
              </Tooltip>
            </div>
          </nav>
          <div className="global-sidebar-border-hover-effect" />
        </div>
      </div>
      <div className="fixed right-0 bottom-1 z-50 bg-white rounded-2xl">
        <OverLimitWarning />
      </div>
    </>
  );
}

export default GlobalSidebarNav;
