import { formatDistanceToNowStrict } from 'date-fns';
import { IconPlaceholder } from 'icons';
import mediaTypeIcons from 'icons/mediaTypeIcons';
import { useTranslation } from 'react-i18next';
import ugcAvatarIcon from 'images/ugc-avatar-icon.png';

import { UgcRequestPreviewProps } from '../UgcRequestPreview';

interface Props {
  account: UgcRequestPreviewProps['account'];
  values: UgcRequestPreviewProps['values'];
  coverPhotoUrl?: string | null;
}

export default function UgcRequestPreviewHome(props: Props) {
  const { account, coverPhotoUrl, values } = props;
  const { t } = useTranslation();

  const MediaTypeIcon = mediaTypeIcons[values.mediaType];
  return (
    <div
      className={`flex flex-col items-center w-full h-full p-2`}
      style={{ background: account.backgroundColorHome }}
    >
      <div className="mb-2 text-center">
        <div className="rounded-full mx-auto mb-1.5 w-8 h-8 bg-grey8">
          {account.logoUrl ? (
            <img
              src={account.logoUrl}
              alt="logo"
              className="rounded-full mx-auto mb-1.5 w-8 h-8"
            />
          ) : (
            <div className="p-1.5">
              <img
                src={ugcAvatarIcon}
                alt={t('photoshelterUGC')}
                className="w-full h-full"
              />
            </div>
          )}
        </div>

        <div
          className="font-bold"
          style={{ color: account.headlineTextColorHome }}
        >
          {account.name}
        </div>
      </div>

      {/* Cover Image */}
      <div
        role="img"
        aria-label={t('ugcRequestPreview.coverPhoto')}
        className="mb-1 relative w-full h-0 bg-grey8 bg-cover bg-no-repeat"
        style={{
          borderRadius: account.cardBorderRadius,
          paddingBottom: '100%',
          backgroundImage: `url('${coverPhotoUrl?.replace(/'/g, "\\'")}')`,
        }}
      >
        {!coverPhotoUrl && (
          <IconPlaceholder className="absolute-center text-lightIcon w-14 h-14" />
        )}
      </div>
      {/* End Cover Image */}

      <div className="flex items-center w-full justify-between space-x-2 mb-1">
        {/* Expiry */}
        <div>
          {!!values.expiresAt && (
            <span className="text-14 text-bodyTextColorHome text-opacity-60">
              {t(
                values.expiresAt > new Date().getTime()
                  ? 'requestPreview.expiresAt'
                  : 'requestPreview.expiredAt',
                {
                  date: formatDistanceToNowStrict(new Date(values.expiresAt)),
                }
              )}
            </span>
          )}
        </div>
        {/* End Expiry */}

        {/* Media Type and Duration Pill */}
        {values.mediaType && (
          <div className="h-2.5 px-1 inline-flex items-center space-x-0.5 bg-white font-body border-default rounded-full">
            {MediaTypeIcon && (
              <div className="flex flex-shrink-0 items-center">
                <MediaTypeIcon className="w-1.75 h-1.75 text-lightIcon" />
              </div>
            )}
            <div className="flex-shrink-0">
              <p className="text-12 leading-16 font-bold text-grey100">
                {t(`requestPreview.mediaType.${values.mediaType}`, {
                  context: values.videoDuration,
                })}
              </p>
            </div>
          </div>
        )}

        {/* End Media Type and Duration Pill */}
      </div>

      {/* Subject and instructions */}
      <div className="w-full text-left">
        <h4
          className="text-20 leading-24 font-headline font-bold"
          style={{ color: account.headlineTextColorHome }}
        >
          {values.subject || t('ugcRequestBuilder.form.subject.placeholder')}
        </h4>

        <span
          className="line-clamp-3 md:line-clamp-5 text-14 leading-20 font-body"
          style={{ color: account.bodyTextColorHome }}
        >
          {values.instructions ||
            t('ugcRequestBuilder.form.instructions.placeholder')}
        </span>
      </div>
      {/* End subject and instructions */}
    </div>
  );
}
