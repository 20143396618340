import api from './index';
import camelize from 'camelize';
import Account from 'types/Account';
import AccountParams from 'types/AccountParams';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateAccount: builder.mutation<Account, Partial<AccountParams>>({
      query: (params) => ({
        url: 'account',
        method: 'PUT',
        body: params,
      }),
      transformResponse: camelize,
    }),
  }),
});

export default extendedApi;

export const { useUpdateAccountMutation } = extendedApi;
