import Form from 'components/Form';
import { useTranslation } from 'react-i18next';
import { IconPortrait, IconLandscape } from 'icons';

interface Props {
  value: string;
  errors?: string[];
  onChange: (value: string) => void;
}

export default function Orientation({ value, onChange, errors }: Props) {
  const { t } = useTranslation();
  return (
    <Form.Field
      required
      label={t('ugcRequestBuilder.form.orientation.label')}
      errors={errors}
    >
      <Form.ToggleGroup>
        <Form.ToggleGroup.Option
          isSelected={value === 'portrait'}
          onSelect={() => onChange('portrait')}
          title={t('ugcRequestBuilder.form.orientation.portrait')}
          icon={IconPortrait}
        />

        <Form.ToggleGroup.Option
          isSelected={value === 'landscape'}
          onSelect={() => onChange('landscape')}
          title={t('ugcRequestBuilder.form.orientation.landscape')}
          icon={IconLandscape}
        />

        <Form.ToggleGroup.Option
          isSelected={value === 'any'}
          onSelect={() => onChange('any')}
          title={t('ugcRequestBuilder.form.orientation.any')}
        />
      </Form.ToggleGroup>
    </Form.Field>
  );
}
