import React from 'react';
import { NavLink } from 'react-router-dom';
import Tooltip from 'components/Tooltip';

interface NavItemProps {
  routeTo: string;
  text: string;
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
      titleId?: string;
      desc?: string;
      descId?: string;
    }
  >;
  isOpen: boolean;
}

export default function NavItem(props: NavItemProps) {
  const { routeTo, text, Icon, isOpen } = props;

  const linkProps = {
    className: `flex ${
      isOpen ? 'delay-50 w-full' : '-pl-0.25 w-4'
    } focus-visible:ring focus-visible:ring-opacity-20 focus:outline-none focus-within:outline-none rounded-md text-grey5 text-14 hover:bg-grey3 active:outline-none mb-2 transition-width duration-300 ease-in-out h-4`,
    activeClassName: 'bg-grey3 !text-white font-medium',
  };

  return (
    <NavLink to={routeTo} {...linkProps}>
      <Tooltip
        theme="primaryDark"
        arrow
        placement="bottom"
        content={text}
        disabled={isOpen}
      >
        <div className="flex items-center">
          <div className="w-4 h-4">
            <Icon className="w-full h-full icon-padding" />
          </div>

          <span
            className={`${
              isOpen
                ? 'transition-opacity ease-in-out delay-150 duration-75 opacity-100 pl-1'
                : 'opacity-0 w-0 p-0'
            }`}
          >
            {text}
          </span>
        </div>
      </Tooltip>
    </NavLink>
  );
}
