import { useHistory, useParams } from 'react-router';
import React from 'react';
import Submission from 'types/Submission';
import {
  SubmissionThumbnailCheckbox,
  SubmissionThumbnailActions,
} from './components';
import routes from 'helpers/routes';
import routeFor from 'helpers/routeFor';
import UgcRequestType from 'types/UgcRequestType';
import SubmissionThumbnailContainer from '../SubmissionThumbnailContainer';
import UgcRequest from 'types/UgcRequest';

interface Props {
  ugcRequest: UgcRequest;
  submission: Submission;
  isSelected: boolean;
  isSelectMode: boolean;
  onToggleSelected: () => void;
}

export default function SubmissionThumbnail(props: Props) {
  const history = useHistory();
  const params = useParams<{ id: string; type: UgcRequestType }>();
  const { submission, ugcRequest, isSelected, onToggleSelected, isSelectMode } =
    props;

  const handleClick = () => {
    if (isSelectMode) {
      onToggleSelected();
    } else {
      history.push(
        `${routeFor(
          routes.requests.submissionShow,
          params.type,
          params.id,
          submission.id
        )}${window.location.search}`
      );
    }
  };

  return (
    <SubmissionThumbnailContainer
      width={submission.width}
      height={submission.height}
      isSelected={isSelected}
    >
      <div className="absolute inset-0 z-10 pointer-events-none bg-thumbnailOverlay opacity-0 group-hover:opacity-100 transition-fast" />

      <SubmissionThumbnailCheckbox
        checked={isSelected}
        isSelectMode={isSelectMode}
        onChange={onToggleSelected}
      />

      <SubmissionThumbnailActions
        ugcRequest={ugcRequest}
        submission={submission}
      />

      <button
        onClick={handleClick}
        className="absolute inset-0 w-full h-full bg-cover"
        style={{ backgroundImage: `url(${submission.thumbnailUrl})` }}
      />

      {isSelected && (
        <div
          className="absolute z-10 rounded-lg inset-0 pointer-events-none"
          style={{ boxShadow: 'inset 0 0 0 1px #fff' }}
        />
      )}
    </SubmissionThumbnailContainer>
  );
}
