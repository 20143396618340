import Btn from 'components/Btn';
import Modal from 'components/Modal';
import { IconArrowRight } from 'icons';
import { useTranslation } from 'react-i18next';
import onboardingBrandTemplate from 'images/onboarding-brand-template.png';
import routes from 'helpers/routes';
import { useUpdateAccountMutation } from 'api/account';
import { useHistory } from 'react-router';
import useFlashMessages from 'hooks/useFlashMessages';

export default function OnboardingStyles() {
  const { t } = useTranslation();
  const [updateAccount, { isLoading }] = useUpdateAccountMutation();
  const { addFlashMessage } = useFlashMessages();
  const history = useHistory();

  const handleContinue = async () => {
    const result = await updateAccount({ onboardingCompleted: true });
    if ('error' in result) {
      addFlashMessage(t('global.unexpectedError'), { isError: true });
    } else {
      history.push(routes.settings.brand);
    }
  };
  return (
    <Modal
      isOpen
      onRequestClose={() => {}}
      theme="onboarding"
      renderHeading={t('onboarding.styles.heading')}
      contentClassNames="!px-0"
      renderActions={
        <Btn
          onClick={handleContinue}
          variant="inlineBlock"
          rightIcon={IconArrowRight}
          isLoading={isLoading}
          disabled={isLoading}
        >
          {t('onboarding.styles.continue')}
        </Btn>
      }
    >
      <div className="text-dark text-14 px-6 mb-4">
        {t('onboarding.styles.description')}
      </div>

      <img
        src={onboardingBrandTemplate}
        className="w-full"
        alt={t('onboarding.styles.alt')}
      />
    </Modal>
  );
}
