import Form from 'components/Form';
import { useTranslation } from 'react-i18next';
import SubmissionFilter from 'types/SubmissionFilter';
import { IconThumbsDownFilled, IconThumbsUpFilled } from 'icons';

interface Props {
  value: SubmissionFilter['rating'];
  onChange: (rating: SubmissionFilter['rating']) => void;
}

export default function SubmissionRatingFilter({ value, onChange }: Props) {
  const { t } = useTranslation();
  return (
    <Form.ToggleGroup variant="white">
      <Form.ToggleGroup.Option
        isSelected={!value}
        onSelect={() => onChange(null)}
        title={t('ugcRequestShow.submissionRatingFilter.null')}
      />

      <Form.ToggleGroup.Option
        isSelected={value === 'starred'}
        onSelect={() => onChange('starred')}
        icon={IconThumbsUpFilled}
        title={t('ugcRequestShow.submissionRatingFilter.starred')}
      />

      <Form.ToggleGroup.Option
        isSelected={value === 'rejected'}
        onSelect={() => onChange('rejected')}
        icon={IconThumbsDownFilled}
        title={t('ugcRequestShow.submissionRatingFilter.rejected')}
      />

      <Form.ToggleGroup.Option
        isSelected={value === 'unrated'}
        onSelect={() => onChange('unrated')}
        title={t('ugcRequestShow.submissionRatingFilter.unrated')}
      />
    </Form.ToggleGroup>
  );
}
