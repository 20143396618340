import {
  IconArrowDown,
  IconArrowLeft,
  IconArrowRight,
  IconArrowUp,
} from 'icons';
import { createElement } from 'react';
import { useTranslation } from 'react-i18next';

export default function SubmissionSidebarShortcuts() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="text-light uppercase text-12 font-bold mb-2 leading-none">
        {t('ugcRequestShow.submissions.sidebar.keyboardShortcuts.heading')}
      </div>

      <Shortcut
        icon={IconArrowRight}
        title={t(
          'ugcRequestShow.submissions.sidebar.keyboardShortcuts.nextPhoto'
        )}
      />

      <Shortcut
        icon={IconArrowLeft}
        title={t(
          'ugcRequestShow.submissions.sidebar.keyboardShortcuts.prevPhoto'
        )}
      />

      <Shortcut
        icon={IconArrowUp}
        title={t(
          'ugcRequestShow.submissions.sidebar.keyboardShortcuts.likePhoto'
        )}
      />

      <Shortcut
        icon={IconArrowDown}
        title={t(
          'ugcRequestShow.submissions.sidebar.keyboardShortcuts.rejectPhoto'
        )}
      />
    </div>
  );
}

interface ShortcutProps {
  icon: typeof IconArrowLeft;
  title: string;
}

function Shortcut({ icon, title }: ShortcutProps) {
  return (
    <div className="flex items-center mb-0.5 last:mb-0">
      <div className="flex items-center justify-center mr-1 border-2 border-solid border-darkText rounded-lg w-4.5 h-4">
        {createElement(icon, { className: 'block w-2.5 h-2.5 text-dark' })}
      </div>

      <div className="text-dark text-14 bump-up-1">{title}</div>
    </div>
  );
}
