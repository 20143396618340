import api from './index';
import camelize from 'camelize';
import FetchCurrentUserResponse from 'types/FetchCurrentUserResponse';
import SubscriptionParams from 'types/SubscriptionParams';
import Subscription from 'types/Subscription';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchSubscription: builder.query<Subscription, void>({
      query: () => ({ url: 'subscription' }),
      transformResponse: camelize,
      providesTags: ['Subscription'],
    }),
  }),
});

export default extendedApi;

export const { useFetchSubscriptionQuery } = extendedApi;
