import Form from 'components/Form';
import { IconPlus } from 'icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  value: string[];
  onChange: (value: string[]) => void;
}

export default function TalkingPoints(props: Props) {
  const { value, onChange } = props;
  const { t } = useTranslation();
  const [didAdd, setDidAdd] = useState(false);

  const handleAddTalkingPoint = () => {
    setDidAdd(true);
    onChange([...value, '']);
  };

  const handleChange = (idx: number, change: string) => {
    const nextValue = [...value];
    nextValue[idx] = change;
    onChange(nextValue);
  };

  const handleRemove = (idx: number) => {
    setDidAdd(true);
    onChange(value.filter((_, i) => i !== idx));
  };

  return (
    <Form.Field label={t('ugcRequestBuilder.form.talkingPoints.label')}>
      {value.map((talkingPoint, i) => (
        <div key={i} className="mb-1">
          <Form.TextArea
            value={talkingPoint}
            autoSize
            onChange={(e) => handleChange(i, e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleAddTalkingPoint();
              }
            }}
            placeholder={
              i < 3
                ? t(`ugcRequestBuilder.form.talkingPoints.placeholders.${i}`)
                : t('ugcRequestBuilder.form.talkingPoints.placeholders.default')
            }
            autoFocus={didAdd}
            maxLength={500}
            onClear={value.length > 1 ? () => handleRemove(i) : undefined}
          />
        </div>
      ))}

      {value.length < 8 && (
        <button
          className="btn btn--grey btn--small inline-block w-auto"
          onClick={handleAddTalkingPoint}
        >
          <span className="flex items-center">
            <IconPlus className="text-light" />
            <span>{t(`ugcRequestBuilder.form.talkingPoints.add`)}</span>
          </span>
        </button>
      )}
    </Form.Field>
  );
}
