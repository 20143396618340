import { useUpdateAccountUserMutation } from 'api/accountUser';
import Form from 'components/Form';
import useFlashMessages from 'hooks/useFlashMessages';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AccountUser from 'types/AccountUser';
import User from 'types/User';

interface Props {
  accountUser: AccountUser;
  currentUser: User;
}

export default function SettingsUsersRole(props: Props) {
  const { accountUser, currentUser } = props;
  const { addFlashMessage } = useFlashMessages();
  const [updateAccountUser] = useUpdateAccountUserMutation();

  const { t } = useTranslation();

  const [role, setRole] = useState(accountUser.role);

  const options = [
    {
      value: 'member',
      label: t('settings.users.roles.member.label'),
    },
    {
      value: 'admin',
      label: t('settings.users.roles.admin.label'),
    },
  ];

  const selectedOption = options.find((o) => o.value === role);

  const handleChange = async (value: string) => {
    const prevRole = role;
    setRole(value);
    const result = await updateAccountUser({
      id: accountUser.id,
      role: value,
    });

    if ('error' in result) {
      setRole(prevRole);
      addFlashMessage(t('global.unexpectedError'), { isError: true });
    } else {
      addFlashMessage(t('settings.users.roleUpdatedSuccess'));
    }
  };
  return (
    <Form.Select
      skipField
      options={options}
      isDisabled={accountUser.user.id === currentUser.id}
      onChange={(o) => (o ? handleChange(o.value) : undefined)}
      value={selectedOption}
      size="small"
    />
  );
}
