import api from './index';
import camelize from 'camelize';
import qs from 'qs';
import Submission from 'types/Submission';
import SubmissionFilter from 'types/SubmissionFilter';
import PaginatedResponse from 'types/PaginatedResponse';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchSubmissions: builder.query<
      PaginatedResponse<{ submissions: Submission[] }>,
      { requestId: number; filter: SubmissionFilter; cursor: string }
    >({
      query: ({ requestId, filter, cursor }) => ({
        url: `requests/${requestId}/submissions?cursor=${cursor}&${qs.stringify(
          filter
        )}`,
      }),
      transformResponse: camelize,
    }),

    fetchSubmission: builder.query<
      Submission,
      { id: number; filter: SubmissionFilter }
    >({
      query: ({ id, filter }) => ({
        url: `submissions/${id}?${qs.stringify(filter)}`,
      }),
      transformResponse: camelize,
    }),

    updateSubmission: builder.mutation<
      Submission,
      {
        submission: Submission;
        changes: { rating: Submission['rating'] };
      }
    >({
      query: ({ submission, changes }) => ({
        url: `submissions/${submission.id}`,
        method: 'PUT',
        body: changes,
      }),
      transformResponse: camelize,
    }),

    bulkUpdateSubmissions: builder.mutation<
      { success: boolean },
      {
        submissions: Submission[];
        changes: { rating: Submission['rating'] };
      }
    >({
      query: ({ submissions, changes }) => ({
        url: `submissions/bulk_update`,
        method: 'PUT',
        body: { ids: submissions.map((f) => f.id), ...changes },
      }),
      transformResponse: camelize,
    }),

    bulkDeleteSubmissions: builder.mutation<
      { success: boolean },
      { submissions: Submission[]; ugcRequestId: number }
    >({
      query: ({ submissions }) => ({
        url: `submissions/bulk_destroy`,
        method: 'DELETE',
        body: { ids: submissions.map((f) => f.id) },
      }),
      transformResponse: camelize,
      invalidatesTags: (result, error, arg) => [
        { type: 'UgcRequest', id: arg.ugcRequestId },
      ],
    }),

    downloadSubmission: builder.query<
      { url: string },
      { id: Submission['id'] }
    >({
      query: ({ id }) => ({ url: `submissions/${id}/download` }),
      transformResponse: camelize,
    }),

    bulkDownloadSubmissions: builder.mutation<
      { uuid: string },
      { ids: number[]; socketId: string | null }
    >({
      query: (params) => ({
        url: `submissions/bulk_download`,
        method: 'PUT',
        body: params,
      }),
      transformResponse: camelize,
    }),
  }),
});

export default extendedApi;

export const {
  useLazyFetchSubmissionsQuery,
  useLazyDownloadSubmissionQuery,
  useFetchSubmissionsQuery,
  useFetchSubmissionQuery,
  useUpdateSubmissionMutation,
  useBulkUpdateSubmissionsMutation,
  useBulkDeleteSubmissionsMutation,
  useBulkDownloadSubmissionsMutation,
} = extendedApi;
