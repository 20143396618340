import UgcRequest from 'types/UgcRequest';
import UgcRequestType from 'types/UgcRequestType';

export default function getTypeFromUgcRequest(
  ugcRequest?: UgcRequest
): UgcRequestType {
  if (!ugcRequest) return 'live';
  if (ugcRequest.status === 'draft') return 'draft';
  if (ugcRequest.expiresAt && ugcRequest.expiresAt < new Date().getTime()) {
    return 'expired';
  }
  return 'live';
}
