import useOverLimit from 'hooks/useOverLimit';
import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { IconAlert } from 'icons';
import Modal from 'components/Modal';
import usePlan from 'hooks/usePlan';
import formatBytes from 'helpers/formatBytes';
import formatDate from 'helpers/formatDate';

export default function OverLimitWarning() {
  const overLimit = useOverLimit();
  const { t } = useTranslation();
  const plan = usePlan();
  const [isOpen, setIsOpen] = useState(true);

  if (!overLimit) return null;

  const formatter = Intl.NumberFormat('en-US');

  return (
    <>
      <button
        className="btn btn--error btn--small mr-1 btn--inlineBlock"
        onClick={() => setIsOpen(true)}
      >
        <span className="flex items-center">
          <IconAlert />
          <span>{t(`overLimitWarning.buttonText.${overLimit}`)}</span>
        </span>
      </button>

      <Modal
        isOpen={isOpen}
        theme="notification"
        onRequestClose={() => setIsOpen(false)}
        renderHeading={t(`overLimitWarning.modalHeading.${overLimit}`)}
        renderActions={
          <>
            <a
              className="btn btn--primary btn--small"
              href="mailto:support@socialie.com"
              target="_blank"
              rel="noreferrer nofollow"
            >
              {t('overLimitWarning.contactUs')}
            </a>

            <button
              className="btn btn--grey btn--small"
              onClick={() => setIsOpen(false)}
            >
              {t('global.dismiss')}
            </button>
          </>
        }
      >
        <Trans
          i18nKey={`overLimitWarning.modalDescription.${overLimit}`}
          values={{
            limit:
              overLimit === 'storage'
                ? formatBytes(plan.maxStorageInGb * 1024 * 1024 * 1024)
                : formatter.format(plan.maxUploadsPerTerm),
            resetDate: formatDate(plan.currentTermEnd || 0),
          }}
          components={{ p: <p className="mb-1" /> }}
        />
      </Modal>
    </>
  );
}
