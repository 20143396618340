import { useTranslation } from 'react-i18next';
import Form from 'components/Form';
import UgcRequestType from 'types/UgcRequestType';
import { IconLoader } from 'icons';
import UgcRequestsSidebarItem from './UgcRequestsSidebarItem';
import UgcRequest from 'types/UgcRequest';
import { useHistory } from 'react-router-dom';
import routeFor from 'helpers/routeFor';
import routes from 'helpers/routes';
import usePlan from 'hooks/usePlan';
import Usage from 'components/Usage';

interface Props {
  type: UgcRequestType;
  ugcRequestsByType: { [key in UgcRequestType]: UgcRequest[] };
  isLoading: boolean;
}

export default function UgcRequestsSidebar(props: Props) {
  const { type, ugcRequestsByType, isLoading } = props;
  const history = useHistory();
  const ugcRequests = ugcRequestsByType[type];

  const { t } = useTranslation();

  const plan = usePlan();

  const allTypes = ['live', 'expired', 'draft'] as UgcRequestType[];

  return (
    <div className="sidebar flex flex-col bg-white h-full">
      <div className="bg-grey9 border-b-default pt-2.5 px-1.5 pb-1">
        <div className="flex items-center mb-1">
          <div className="text-dark uppercase text-12 font-bold">
            {t('ugcRequests.sidebar.requests')}
          </div>
        </div>

        <div className="mb-0.5">
          <Form.ToggleGroup>
            {allTypes.map((value) => (
              <Form.ToggleGroup.Option
                key={value}
                onSelect={() =>
                  history.push(routeFor(routes.requests.index, value))
                }
                isSelected={type === value}
                title={t(`ugcRequestTypes.${value}`)}
              />
            ))}
          </Form.ToggleGroup>
        </div>

        <div className="text-12 text-light">
          {isLoading ? (
            <IconLoader className="block w-2 h-2 text-light" />
          ) : (
            t(`ugcRequests.sidebar.requestCount.${type}`, {
              count: ugcRequests?.length,
            })
          )}
        </div>
      </div>

      <div className="flex-1 p-1 overflow-y-auto">
        {ugcRequests?.map((ugcRequest) => (
          <UgcRequestsSidebarItem key={ugcRequest.id} ugcRequest={ugcRequest} />
        ))}
      </div>

      {!isLoading && (
        <div className="border-t-default px-1.5 py-1.5">
          {!!plan.maxUploadsPerTerm && (
            <div className="mb-1 last:mb:0 text-light">
              <Usage.Submissions />
            </div>
          )}

          <Usage.Requests used={ugcRequestsByType.live.length} />
        </div>
      )}
    </div>
  );
}
