const routes = {
  auth: {
    logIn: '/log-in',
    logInRecovery: '/log-in/recovery-code',
  },
  ugcRequestBuilder: '/builder/:id',
  index: '/',
  requests: {
    index: '/requests/:type?',
    show: '/requests/:type/:id',
    edit: '/requests/:type/:id/edit',
    build: '/requests/:type/:id/build',
    submissionShow: '/requests/:type/:id/submissions/:submissionId',
  },
  settings: {
    brand: '/settings/brand',
    profile: '/settings/my-profile',
    root: '/settings',
    subscription: '/settings/subscription',
    users: '/settings/users',
    domains: {
      root: '/settings/domains',
      dns: '/settings/domains/:id/dns',
      ssl: '/settings/domains/:id/ssl',
      new: '/settings/domains/new',
    },
  },
};

export default routes;
