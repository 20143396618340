import useKey from '@rooks/use-key';
import { useFetchSubmissionQuery } from 'api/submissions';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Loader from 'components/Loader';
import routeFor from 'helpers/routeFor';
import routes from 'helpers/routes';
import { IconArrowLeft, IconArrowRight } from 'icons';
import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { getSubmission } from 'redux/reducers/submissions';
import { useAppSelector } from 'redux/store';
import UgcRequest from 'types/UgcRequest';
import UgcRequestType from 'types/UgcRequestType';
import SubmissionFilter from 'types/SubmissionFilter';
import { SubmissionSidebar, SubmissionShowMedia } from './components';

interface Props {
  ugcRequest: UgcRequest;
  filter: SubmissionFilter;
}

export default function SubmissionShow(props: Props) {
  const overlayRef = useRef<HTMLDivElement>(null);
  const { ugcRequest, filter } = props;
  const params =
    useParams<{ id: string; type: UgcRequestType; submissionId: string }>();
  const submissionId = parseInt(params.submissionId, 10);
  const history = useHistory();
  const submission = useAppSelector((state) =>
    getSubmission(state, submissionId)
  );

  const { t } = useTranslation();

  const { isFetching } = useFetchSubmissionQuery(
    { id: submissionId, filter },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    const currentEl = overlayRef.current;
    if (currentEl) disableBodyScroll(currentEl);

    return () => {
      if (currentEl) enableBodyScroll(currentEl);
    };
  }, []);

  const handleClose = () => {
    history.push(
      `${routeFor(routes.requests.show, params.type, params.id)}${
        window.location.search
      }`
    );
  };

  const handleOpenNext = () => {
    if (!submission?.nextId) return;
    handleNavigate(submission.nextId);
  };

  const handleOpenPrev = () => {
    if (!submission?.prevId) return;
    handleNavigate(submission.prevId);
  };

  const handleNavigate = (id: number) => {
    history.push(
      routeFor(routes.requests.submissionShow, params.type, params.id, id)
    );
  };

  useKey(['Escape'], handleClose);
  useKey(['ArrowRight'], handleOpenPrev);
  useKey(['ArrowLeft'], handleOpenNext);

  return createPortal(
    <>
      {/* Overlay */}
      <div
        ref={overlayRef}
        onClick={(e) => {
          if (e.currentTarget === e.target) handleClose();
        }}
        className="flex items-stretch fixed inset-0 overflow-hidden z-detailView bg-black bg-opacity-80 transition-default"
      >
        <div className="relative overflow-hidden flex-1 flex items-center space-x-2 justify-center p-2 pointer-events-none">
          <button
            className="w-3 h-3 text-white disabled:opacity-50 pointer-events-auto"
            disabled={!submission?.nextId}
            onClick={handleOpenNext}
          >
            <IconArrowLeft className="block w-full h-full" />
          </button>

          <div className="flex-1 h-full overflow-hidden">
            {isFetching ? (
              <div className="absolute-v-center">
                <Loader />
              </div>
            ) : !!submission ? (
              <SubmissionShowMedia submission={submission} />
            ) : (
              <div className="notice notice--error max-w-60 absolute-center pointer-events-auto">
                <p>{t('ugcRequestShow.submissions.errorSingle')}</p>

                <Link
                  to={`${routeFor(
                    routes.requests.show,
                    params.type,
                    params.id
                  )}${window.location.search}`}
                  className="btn btn--darkGrey btn--inlineBlock mt-3"
                >
                  {t('global.close')}
                </Link>
              </div>
            )}
          </div>

          <button
            className="w-3 h-3 text-white disabled:opacity-50 pointer-events-auto"
            disabled={!submission?.prevId}
            onClick={handleOpenPrev}
          >
            <IconArrowRight className="block w-full h-full" />
          </button>
        </div>

        <SubmissionSidebar
          submission={submission}
          ugcRequest={ugcRequest}
          isFetching={isFetching}
          onClose={handleClose}
          onOpenNext={submission?.nextId ? handleOpenNext : undefined}
          onOpenPrev={submission?.prevId ? handleOpenPrev : undefined}
        />
      </div>
    </>,
    document.body
  );
}
