import { useFetchSubmissionsQuery } from 'api/submissions';
import Loader from 'components/Loader';
import useFilterManager from 'hooks/useFilterManager';
import useSelectable, { SelectableProvider } from 'hooks/useSelectable';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSubmissions } from 'redux/reducers/submissions';
import { useAppSelector } from 'redux/store';
import UgcRequest from 'types/UgcRequest';
import SubmissionFilter from 'types/SubmissionFilter';
import {
  SubmissionList,
  SubmissionRatingFilter,
  SubmissionsNoResults,
  SubmissionBulkActions,
  SubmissionSort,
  SubmissionShow,
} from './components';
import { Route } from 'react-router';
import routes from 'helpers/routes';

interface Props {
  ugcRequest: UgcRequest;
}

const defaultFilter: SubmissionFilter = {
  rating: null,
  sort: 'newest',
};

export default function UgcRequestSubmissions({ ugcRequest }: Props) {
  const { t } = useTranslation();
  const { filter, updateFilter, debouncedFilter } = useFilterManager(
    defaultFilter,
    true
  );
  const [cursor, setCursor] = useState('');

  const handleUpdateFilter: typeof updateFilter = (changes, options) => {
    setCursor('');
    updateFilter(changes, options);
  };

  const submissions = useAppSelector(getSubmissions);
  const metadata = useAppSelector((state) => state.submissions.metadata);
  const { isFetching, error } = useFetchSubmissionsQuery(
    { requestId: ugcRequest.id, cursor, filter: debouncedFilter },
    { refetchOnMountOrArgChange: true }
  );

  const loadNextPage = useCallback(() => {
    if (!!metadata?.cursor && metadata?.cursor !== cursor) {
      setCursor(metadata.cursor);
    }
  }, [metadata.cursor, cursor]);

  const hasNextPage =
    !isFetching &&
    !!submissions.length &&
    submissions.length < metadata.totalCount;

  // If the user deletes an entire page, load up the next one
  useEffect(() => {
    if (!submissions.length && !!metadata.totalCount && hasNextPage)
      loadNextPage();
  }, [submissions.length, metadata.totalCount, hasNextPage, loadNextPage]);

  const selectable = useSelectable(submissions.map((f) => f.id));

  return (
    <>
      <SelectableProvider value={selectable}>
        <div className="relative px-3 p-2 with-topbar">
          {error ? (
            <div className="notice notice--error self-start">
              {t('ugcRequestShow.submissions.error')}
            </div>
          ) : isFetching && cursor === '' ? (
            <Loader />
          ) : !metadata.totalCount ? (
            <SubmissionsNoResults
              onResetFilters={() =>
                handleUpdateFilter(defaultFilter, { debounce: false })
              }
            />
          ) : (
            <>
              <div className="mb-4 flex justify-between items-center">
                {metadata && (
                  <SubmissionBulkActions
                    ugcRequest={ugcRequest}
                    totalCount={metadata.totalCount}
                    loadedCount={submissions.length}
                  />
                )}

                <div>
                  <SubmissionRatingFilter
                    value={filter.rating}
                    onChange={(rating) => handleUpdateFilter({ rating })}
                  />
                </div>

                <div>
                  <SubmissionSort
                    value={filter.sort}
                    onChange={(value) => handleUpdateFilter({ sort: value })}
                  />
                </div>
              </div>

              <SubmissionList
                submissions={submissions}
                ugcRequest={ugcRequest}
                isFetching={isFetching}
                hasNextPage={hasNextPage}
                loadNextPage={loadNextPage}
              />
            </>
          )}
        </div>
      </SelectableProvider>

      <Route path={routes.requests.submissionShow}>
        <SubmissionShow ugcRequest={ugcRequest} filter={filter} />
      </Route>
    </>
  );
}
