import formatDate from 'helpers/formatDate';
import { add } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Subscription from 'types/Subscription';
import SettingsSubscriptionSection from './SettingsSubscriptionSection';

interface Props {
  subscription: Subscription;
}

export default function SettingsSubscriptionPlan(props: Props) {
  const { subscription } = props;
  const { status } = subscription;
  const { t } = useTranslation();
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <SettingsSubscriptionSection
      heading={
        <span className="flex items-center">
          <span className="mr-1">
            {t('settings.subscription.plan.yourPlan')}
          </span>
          {status === 'in_trial' && (
            <span className="bump-down-2 flex items-center rounded-full text-brightBlue bg-blueBg px-1.5 h-3">
              <span className="text-14 font-bold tracking-normal">
                {t('settings.subscription.plan.trial')}
              </span>
            </span>
          )}
        </span>
      }
    >
      <h3 className="h3">{subscription.plan.name}</h3>
      <div className="text-dark text-14 mb-1.5">
        {['active', 'in_trial'].includes(status)
          ? t('settings.subscription.plan.active')
          : status === 'cancelled'
          ? t('settings.subscription.plan.cancelled')
          : status === 'paused'
          ? t('settings.subscription.plan.paused')
          : null}
      </div>
    </SettingsSubscriptionSection>
  );
}
