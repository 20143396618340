import { useLogOutMutation } from 'api/auth';
import AuthPage from 'components/AuthPage';
import { IconArrowRight, IconCaretRight } from 'icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import User from 'types/User';
import noLinkedAccountImage from 'images/ugc-no-linked-accounts.png';

interface Props {
  currentUser: User;
}

export default function SelectAccount({ currentUser }: Props) {
  const { accounts } = currentUser;
  const { t } = useTranslation();

  const [logOut] = useLogOutMutation();

  const handleLogOut = async () => {
    await logOut();
    window.location.reload();
  };

  if (!accounts.length) {
    return (
      <AuthPage>
        <div className="mb-4">
          <div className="text-center max-w-48 text-grey1">
            <img
              src={noLinkedAccountImage}
              className="w-full"
              alt={t('auth.selectAccount.heading')}
            />
            <h2 className="text-24 leading-28 font-sans font-semibold pb-4 px-2">
              {t('auth.selectAccount.heading')}
            </h2>
            <p className="pb-4">
              {t('auth.selectAccount.noAccounts.description')}
            </p>
            <div className="block my-4">
              <button
                className="btn btn--inlineBlock btn--grey mr-1"
                onClick={() => handleLogOut()}
              >
                {t('auth.selectAccount.noAccounts.signOut')}
              </button>
              <a
                className="btn btn--inlineBlock btn--primary ml-1"
                href="https://go.photoshelter.com/brands/photoshelter-digital-asset-management-lp-062024/"
                target="_blank"
                rel="noreferrer"
              >
                <span className="flex">
                  {t('auth.selectAccount.noAccounts.learnMore')}
                  <IconArrowRight className="block w-full h-full" />
                </span>
              </a>
            </div>
          </div>
        </div>
      </AuthPage>
    );
  }

  return (
    <AuthPage heading={t('auth.selectAccount.heading')}>
      {accounts.map((account) => (
        <a
          key={account.id}
          className="block w-full mb-1 last:mb-0 rounded-lg border-default px-2 py-1.5 hover:bg-grey9 transition-default"
          href={`/${account.brand}`}
        >
          <span className="flex items-center">
            <span className="mr-2 flex-shrink-0 w-7 h-7 bg-grey8 rounded-full overflow-hidden">
              {account.logoUrl && (
                <img
                  src={account.logoUrl}
                  className="w-full h-full rounded-full object-cover"
                  alt="Logo"
                />
              )}
            </span>

            <span className="flex-1 truncate mr-2 text-20 font-bold text-dark">
              {account.name}
            </span>

            {React.createElement(IconCaretRight, {
              className: 'w-4 h-4 flex-shrink-0 text-lightIcon',
            })}
          </span>
        </a>
      ))}

      <button
        className="mt-4 btn btn--inlineBlock btn--primary px-4"
        onClick={() => handleLogOut()}
      >
        {t('auth.selectAccount.noAccounts.signOut')}
      </button>
    </AuthPage>
  );
}
