import RecoveryCodeLogInResponse from 'types/RecoveryCodeLogInResponse';
import ValidateOtpRequest from 'types/ValidateOtpRequest';
import ValidateOtpResponse from 'types/ValidateOtpResponse';
import api from './index';
import camelize from 'camelize';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    validateOtp: builder.mutation<ValidateOtpResponse, ValidateOtpRequest>({
      query: (params) => ({
        url: 'mfa/validate-otp',
        method: 'POST',
        body: params,
      }),
      transformResponse: camelize,
      invalidatesTags: (result, error, arg) => {
        if (result && !('error' in result)) {
          return ['Plan'];
        }
        return []; // Don't invalidate on error
      },
    }),

    recoveryCodeLogIn: builder.mutation<
      RecoveryCodeLogInResponse,
      { token: string; recoveryCode: string }
    >({
      query: (params) => ({
        url: 'mfa/recovery-code-log-in',
        method: 'POST',
        body: params,
      }),
      transformResponse: camelize,
      invalidatesTags: (result, error, arg) => {
        if (result && !('error' in result)) {
          return ['Plan'];
        }
        return []; // Don't invalidate on error
      },
    }),
  }),
});

export default extendedApi;

export const { useValidateOtpMutation, useRecoveryCodeLogInMutation } =
  extendedApi;
