import BlurUp from 'components/BlurUp';
import Submission from 'types/Submission';
import { contain } from 'intrinsic-scale';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';

interface Props {
  submission: Submission;
}

export default function SubmissionShowMedia({ submission }: Props) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const originalDimensions = useMemo(
    () => ({
      width: submission.width,
      height: submission.height,
    }),
    [submission.width, submission.height]
  );

  const calculateWidth = useCallback(() => {
    if (!wrapperRef.current) return;
    const { width, height } = originalDimensions;
    const { clientWidth, clientHeight } = wrapperRef.current;
    setDimensions(contain(clientWidth, clientHeight, width, height));
  }, [originalDimensions]);

  useEffect(() => {
    calculateWidth();
  }, [calculateWidth]);

  const style = {
    width: `${dimensions.width}px`,
    height: `${dimensions.height}px`,
  };
  return (
    <div
      className="flex items-center justify-center w-full h-full pointer-events-none"
      ref={wrapperRef}
    >
      {submission.contentType?.match('video') ? (
        <video
          className="pointer-events-auto"
          src={submission.mediaUrl}
          poster={submission.thumbnailUrl}
          style={style}
          controls
        />
      ) : (
        <BlurUp
          className="pointer-events-auto"
          preview={submission.thumbnailUrl}
          srcSet={submission.mediaUrl}
          style={style}
        />
      )}
    </div>
  );
}
