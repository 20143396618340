import Btn from 'components/Btn';
import Modal from 'components/Modal';
import Form from 'components/Form';
import { useTranslation } from 'react-i18next';
import Account from 'types/Account';
import useTranslateErrors from 'hooks/useTranslateErrors';
import { useUpdateAccountMutation } from 'api/account';
import { useState } from 'react';
import useFlashMessages from 'hooks/useFlashMessages';
import { IconArrowRight } from 'icons';
import usePlan from 'hooks/usePlan';

interface Props {
  account: Account;
  onContinue: () => void;
}

export default function OnboardingBrand(props: Props) {
  const { t } = useTranslation();
  const plan = usePlan();
  const translateErrors = useTranslateErrors();
  const { account, onContinue } = props;
  const [value, setValue] = useState(account.brand);
  const { addFlashMessage } = useFlashMessages();

  const [updateAccount, { isLoading }] = useUpdateAccountMutation();
  const [errors, setErrors] = useState<string[] | null>(null);

  const handleSave = async () => {
    const result = await updateAccount({ brand: value });
    if ('error' in result) {
      if ('data' in result.error && result.error.status === 422) {
        setErrors(result.error.data.errors.brand);
      } else {
        addFlashMessage(t('global.unexpectedError'), {
          isError: true,
        });
      }
    } else {
      onContinue();
    }
  };

  const handleChange = (value: string) => {
    setValue(value.replace(/[^A-Za-z0-9\-]/gi, '-'));
  };

  return (
    <Modal
      isOpen
      onRequestClose={() => {}}
      theme="onboarding"
      renderHeading={t('onboarding.brand.heading')}
      renderActions={
        <Btn
          variant="inlineBlock"
          rightIcon={IconArrowRight}
          onClick={handleSave}
          isLoading={isLoading}
          disabled={!value}
        >
          {t('global.continue')}
        </Btn>
      }
    >
      <div className="text-dark text-14 mb-4">
        {t('onboarding.brand.description')}
      </div>

      <div className="mx-auto max-w-40 text-left">
        <Form.Field errors={translateErrors(errors)}>
          <div className="flex items-center justify-center w-full">
            <div className="text-16 text-dark bump-down-1">https://</div>
            <div className="flex-1 mx-0.5">
              <Form.TextInput
                value={value}
                variant="center"
                id="search-brand"
                onChange={(e) => handleChange(e.target.value)}
                autoComplete="socialie-fan-brand"
                placeholder="your-company"
                skipField
              />
            </div>
            <div className="text-16 text-dark bump-down-1">
              .ugc.photoshelter.com/
            </div>
          </div>
        </Form.Field>
      </div>

      {plan.customDomains && (
        <div className="notice notice--callout mx-auto mb-2 mt-3 inline-block max-w-50">
          {t('onboarding.brand.customDomainNotice')}
        </div>
      )}
    </Modal>
  );
}
