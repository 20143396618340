import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import authApi from 'api/auth';
import mfaApi from 'api/mfa';
import userApi from 'api/user';
import accountApi from 'api/account';
import User from 'types/User';

interface AuthState {
  currentUser: User | null;
  semiLoggedInUser: { token: string } | null;
}

const { reducer } = createSlice({
  name: 'auth',
  initialState: { currentUser: null, semiLoggedInUser: null } as AuthState,
  reducers: {},
  extraReducers: (builder) => {
    [authApi.endpoints.logIn.matchFulfilled].forEach((endpoint) => {
      builder.addMatcher(endpoint, (state, { payload }) => {
        if ('error' in payload) return state;

        if (payload.user) {
          state.currentUser = payload.user;
          state.semiLoggedInUser = null;
        } else {
          state.semiLoggedInUser = payload;
          state.currentUser = null;
        }
      });
    });

    [
      authApi.endpoints.fetchCurrentUser.matchFulfilled,
      userApi.endpoints.signUpUser.matchFulfilled,
    ].forEach((match) => {
      builder.addMatcher(match, (state, { payload }) => {
        state.currentUser = payload.user;
      });
    });

    builder.addMatcher(
      mfaApi.endpoints.validateOtp.matchFulfilled,
      (state, { payload }) => {
        if (!('valid' in payload)) {
          state.currentUser = payload.user;
          state.semiLoggedInUser = null;
        }
      }
    );

    builder.addMatcher(
      mfaApi.endpoints.recoveryCodeLogIn.matchFulfilled,
      (state, { payload }) => {
        if (!('errors' in payload)) {
          state.currentUser = payload.user;
          state.semiLoggedInUser = null;
        }
      }
    );

    // Update current user after updating account
    builder.addMatcher(
      accountApi.endpoints.updateAccount.matchFulfilled,
      (state, { payload }) => {
        if (!state.currentUser) return;
        state.currentUser.currentAccount = payload;
        state.currentUser.accounts = state.currentUser.accounts.map((account) =>
          account.id === payload.id ? payload : account
        );
      }
    );

    // Update current user after updating profile
    builder.addMatcher(
      userApi.endpoints.updateProfile.matchFulfilled,
      (state, { payload }) => {
        if (!state.currentUser) return;
        state.currentUser = payload.user;
      }
    );
  },
});

export default reducer;

export const selectCurrentUser = (state: RootState) => state.auth.currentUser;

export const selectSemiLoggedInUser = (state: RootState) =>
  state.auth.semiLoggedInUser;
