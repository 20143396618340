import Form from 'components/Form';
import { useTranslation } from 'react-i18next';
import DataCaptureFieldDefinition from 'types/DataCaptureFieldDefinition';
import UgcRequestParams from 'types/UgcRequestParams';
import { v4 as uuid } from 'uuid';
import DataCaptureField from './DataCaptureField';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

interface Props {
  value: UgcRequestParams['dataCapture'];
  onChange: (value: UgcRequestParams['dataCapture']) => void;
}

export default function DataCapture({ value, onChange }: Props) {
  const { t } = useTranslation();
  const fields = value || [];

  const handleUpdateField = (
    id: string,
    updates: Partial<DataCaptureFieldDefinition>
  ) => {
    onChange(
      fields.map((field) =>
        field.id === id ? { ...field, ...updates } : field
      )
    );
  };

  const handleAddField = () => {
    onChange([
      ...fields,
      { id: uuid(), label: '', type: 'text', required: false },
    ]);
  };

  const handleRemoveField = (id: string) => {
    onChange(fields.filter((field) => field.id !== id));
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const ids = fields.map((f) => f.id);
      const oldIndex = ids.indexOf(active.id);
      const newIndex = ids.indexOf(over.id);
      onChange(arrayMove(fields, oldIndex, newIndex));
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <Form.Section
      title={t('ugcRequestBuilder.form.dataCapture.title')}
      description={t('ugcRequestBuilder.form.dataCapture.description')}
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={fields} strategy={verticalListSortingStrategy}>
          {fields.map((field) => (
            <DataCaptureField
              key={field.id}
              field={field}
              onUpdate={(changes) => handleUpdateField(field.id, changes)}
              onRemove={() => handleRemoveField(field.id)}
            />
          ))}
        </SortableContext>
      </DndContext>

      {fields.length < 10 && (
        <button
          onClick={handleAddField}
          className="text-14 text-brandPrimary underline"
        >
          + {t('ugcRequestBuilder.form.dataCapture.addButton')}
        </button>
      )}
    </Form.Section>
  );
}
