import ShareUrl from 'components/ShareUrl';
import StatusPill from 'components/StatusPill';
import Tooltip from 'components/Tooltip';
import getTypeFromUgcRequest from 'helpers/getTypeFromUgcRequest';
import routeFor from 'helpers/routeFor';
import routes from 'helpers/routes';
import { IconGear } from 'icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import UgcRequest from 'types/UgcRequest';
import { ExpiresAt } from './components';

interface Props {
  ugcRequest: UgcRequest;
}

export default function UgcRequestTopBar({ ugcRequest }: Props) {
  const { t } = useTranslation();
  const subject = ugcRequest.subject || t('global.noSubject');

  return (
    <div className="h-7 px-2 py-0.25 flex items-center border-b-default bg-white">
      <div className="flex-1 overflow-hidden flex items-center mr-4">
        <div className="h4 pb-px truncate" title={subject}>
          {subject}
        </div>

        {getTypeFromUgcRequest(ugcRequest) === 'live' && (
          <div className="ml-1 bump-down-2">
            <StatusPill ugcRequest={ugcRequest} />
          </div>
        )}

        <ExpiresAt ugcRequest={ugcRequest} />
      </div>

      <div className="ml-auto flex flex-shrink-0 items-center space-x-1.5">
        <Tooltip
          content={t('ugcRequestShow.topBar.downloadQrCode')}
          placement="bottom"
          theme="primaryDark"
          arrow
        >
          <a
            href={`${ugcRequest.qrCodeUrl}?download=1&size=400`}
            className="block w-5 h-5"
          >
            <img
              src={`${ugcRequest.qrCodeUrl}?border_modules=0`}
              className="w-5 h-5"
              alt="QR Code"
            />
          </a>
        </Tooltip>
        <ShareUrl url={ugcRequest.url} />

        <Link
          to={routeFor(
            routes.requests.edit,
            getTypeFromUgcRequest(ugcRequest),
            ugcRequest.id
          )}
          className="btn btn--primary inline-block w-auto"
        >
          <span className="flex items-center">
            <IconGear className="block w-2 h-2 -ml-1 mr-1" />
            <span>{t('global.edit')}</span>
          </span>
        </Link>
      </div>
    </div>
  );
}
