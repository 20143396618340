import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

const api = createApi({
  reducerPath: 'api',
  tagTypes: [
    'Account',
    'AccountUser',
    'Domain',
    'UgcRequest',
    'Subscription',
    'Plan',
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.REACT_APP_API_SERVER_URL,
    credentials: 'include',
    prepareHeaders: (headers) => {
      headers.set('X-UGC-Account', window.location.pathname.split('/')[1]);
      return headers;
    },
  }) as BaseQueryFn<
    string | FetchArgs,
    unknown,
    { status: number; data: { errors: any } },
    {}
  >,
  endpoints: () => ({}),
});

export default api;
