import { useCheckDomainDnsMutation } from 'api/domains';
import Btn from 'components/Btn';
import Modal from 'components/Modal';
import useCurrentAccount from 'hooks/useCurrentAccount';
import useFlashMessages from 'hooks/useFlashMessages';
import psl from 'psl';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Domain from 'types/Domain';

interface Props {
  domain: Domain;
  onRequestClose: () => void;
}

export default function SettingsDomainsDNSModal(props: Props) {
  const { domain, onRequestClose } = props;
  const { t } = useTranslation();
  const currentAccount = useCurrentAccount()!;
  const [checkDns, { isLoading }] = useCheckDomainDnsMutation();
  const subdomain = useMemo(() => {
    const parsed = psl.parse(domain.domain);
    return 'subdomain' in parsed ? parsed.subdomain : '';
  }, [domain.domain]);
  const ipAddresses: string[] =
    import.meta.env.REACT_APP_FAN_APP_IP_ADDRESSES?.split(',') || [];
  const defaultDomain = `${currentAccount.brand}.${
    import.meta.env.REACT_APP_FAN_APP_BASE_DOMAIN
  }`;
  const { addFlashMessage } = useFlashMessages();

  const handleCheckDns = async () => {
    const result = await checkDns(domain.id);
    if ('error' in result) {
      addFlashMessage(t('global.unexpectedError'), { isError: true });
    }
    onRequestClose();
  };

  return (
    <Modal
      onRequestClose={onRequestClose}
      renderHeading={t('settings.domains.dns.modalHeading')}
      theme="large"
    >
      <ol className="mb-3 instruction-list">
        <li>{t(`settings.domains.instructions.0`)}</li>
        {domain.isNaked ? (
          ipAddresses.map((ipAddress, idx) => (
            <Steps
              headingStep={idx === 0 ? '1' : '1a'}
              type="A"
              host="@"
              value={ipAddress}
            />
          ))
        ) : (
          <Steps
            type="CNAME"
            host={subdomain || ''}
            value={`${defaultDomain}.`}
          />
        )}
      </ol>

      {t(`settings.domains.modalDelayNote`)}

      <Modal.Actions>
        <Btn
          onClick={handleCheckDns}
          isLoading={isLoading}
          variant="inlineBlock"
        >
          {t('settings.domains.dns.modalRecheck')}
        </Btn>
      </Modal.Actions>
    </Modal>
  );
}

interface StepsProps {
  headingStep?: string;
  type: string;
  host: string;
  value: string;
}

function Steps(props: StepsProps) {
  const { headingStep = 1, type, host, value } = props;
  const { t } = useTranslation();
  return (
    <>
      <li>{t(`settings.domains.instructions.${headingStep}`)}</li>
      <li>
        {t(`settings.domains.instructions.2`)} <strong>{type}</strong>
      </li>
      <li>
        {t(`settings.domains.instructions.3`)} <strong>{host}</strong>
      </li>
      <li>
        {t(`settings.domains.instructions.4`)} <strong>{value}</strong>
      </li>
    </>
  );
}
