import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthPage from 'components/AuthPage';
import Form from 'components/Form';
import { useRecoveryCodeLogInMutation } from 'api/mfa';

interface Props {
  semiLoggedInUser: { token: string };
  onCancel: () => void;
}

export default function RecoveryCodeLogIn(props: Props) {
  const { semiLoggedInUser, onCancel } = props;
  const [logIn, { data, isLoading, error }] = useRecoveryCodeLogInMutation();
  const { t } = useTranslation();
  const [code, setCode] = useState('');

  const handleSubmit = async () => {
    logIn({ token: semiLoggedInUser.token, recoveryCode: code });
  };

  const hasError = error || (data && 'error' in data);

  return (
    <AuthPage heading={t('auth.logIn.heading')}>
      {hasError && (
        <div className="notice notice--error">
          {t('auth.mfaLogIn.invalidCode')}
        </div>
      )}

      <Form onSubmit={handleSubmit}>
        <div className="mb-3">
          <Form.TextInput
            name="code"
            value={code}
            label={t('auth.recoveryCodeLogIn.label')}
            onChange={(e) => setCode(e.target.value)}
            placeholder={t('auth.mfaLogIn.placeholder')}
          />
        </div>

        <button
          type="submit"
          className="btn btn--primary mb-3"
          disabled={isLoading || !code}
          data-loading={isLoading}
        >
          {t('auth.mfaLogIn.submit')}
        </button>

        <div className="flex items-center justify-center">
          <button
            onClick={onCancel}
            className="text-brandPrimary hover:underline"
          >
            {t('auth.mfaLogin.backTo2fa')}
          </button>
        </div>
      </Form>
    </AuthPage>
  );
}
