import { useState } from 'react';
import * as Sentry from '@sentry/react';
import { useFetchCurrentUserQuery } from 'api/auth';
import { useFetchPlanQuery } from 'api/plan';
import ErrorPage from 'components/ErrorPage';
import LoadingPage from 'components/LoadingPage';
import routeFor from 'helpers/routeFor';
import routes from 'helpers/routes';
import usePlan from 'hooks/usePlan';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { selectCurrentUser } from 'redux/reducers/auth';
import { useAppSelector } from 'redux/store';
import ErrorFallback from './components/ErrorFallback';
import FlashMessages from './components/FlashMessages';
import GlobalSidebarNav from 'components/GlobalSidebarNav/GlobalSidebarNav';
import UgcRequests from 'screens/UgcRequests';
import Auth from 'screens/Auth';
import Settings from 'screens/Settings';
import User from 'types/User';

export default function Layout() {
  const { isLoading, error } = useFetchCurrentUserQuery();
  const { isLoading: isLoadingPlan } = useFetchPlanQuery();
  const currentUser = useAppSelector(selectCurrentUser);
  const plan = usePlan();

  if (import.meta.env.REACT_APP_ENABLE_SENTRY === 'true' && currentUser) {
    Sentry.setUser({
      id: currentUser.id.toString(),
      username: currentUser.name,
    });

    const currentAccount = currentUser.currentAccount;

    if (currentAccount) {
      Sentry.setTag('ugc_account.id', currentAccount.id);
      Sentry.setTag('ugc_account.name', currentAccount.name);
      Sentry.setTag('ugc_account.brand', currentAccount.brand);
    }
  }

  const [isGlobalSidebarNavOpen, setIsGlobalSidebarNavOpen] = useState(false);

  const [accountBasename, isRedirectingToDefaultAccount] =
    getAccountBasename(currentUser);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div>
        <Helmet title="PhotoShelter UGC" />
        <FlashMessages />

        {isLoading || isLoadingPlan || isRedirectingToDefaultAccount ? (
          <LoadingPage />
        ) : error ? (
          <ErrorPage />
        ) : currentUser && currentUser.currentAccount ? (
          <BrowserRouter basename={accountBasename}>
            {!plan.isActive ? (
              <Switch>
                <Route path={routes.settings.root}>
                  <Settings
                    renderNav={() => (
                      <GlobalSidebarNav
                        isOpen={isGlobalSidebarNavOpen}
                        handleOnOpen={setIsGlobalSidebarNavOpen}
                      />
                    )}
                  />
                </Route>

                <Redirect to={routes.settings.subscription} />
              </Switch>
            ) : (
              <Switch>
                <Route>
                  <Switch>
                    <Route path={[routes.requests.show, routes.requests.index]}>
                      <UgcRequests
                        renderNav={() => (
                          <GlobalSidebarNav
                            isOpen={isGlobalSidebarNavOpen}
                            handleOnOpen={setIsGlobalSidebarNavOpen}
                          />
                        )}
                      />
                    </Route>

                    <Route path={routes.settings.root}>
                      <Settings
                        renderNav={() => (
                          <GlobalSidebarNav
                            isOpen={isGlobalSidebarNavOpen}
                            handleOnOpen={setIsGlobalSidebarNavOpen}
                          />
                        )}
                      />
                    </Route>

                    <Redirect to={routeFor(routes.requests.index, 'live')} />
                  </Switch>
                </Route>
              </Switch>
            )}
          </BrowserRouter>
        ) : (
          <BrowserRouter>
            <Auth currentUser={currentUser} />
          </BrowserRouter>
        )}
      </div>
    </ErrorBoundary>
  );
}

// Returns the basename for the current suggester, or the default suggester if
// the user is not logged in. Also returns a boolean indicating whether the
// user is being redirected to the default suggester.
const LAST_BASENAME_KEY = 'lastBasename';

function getAccountBasename(
  currentUser: User | null
): [string | undefined, boolean] {
  // Non-logged in users aren't in a suggester so they get regular URLs
  if (!currentUser || !currentUser?.accounts.length) return [undefined, false];

  const currentBasename = window.location.pathname.split('/')[1];

  // We don't want to namespace invitation urls
  if (currentBasename === 'invites') return [undefined, false];

  const allowedBasenames = currentUser.accounts.map((a) => a.brand);

  // The user is already on a URL that is nested under a suggester's brand,
  // so we'll use that
  if (allowedBasenames.includes(currentBasename)) {
    window.localStorage.setItem(LAST_BASENAME_KEY, currentBasename);
    return [`/${currentBasename}`, false];
  }

  let defaultBasename =
    window.localStorage.getItem(LAST_BASENAME_KEY) || allowedBasenames[0];

  // User has no suggesters
  if (!defaultBasename) return [undefined, true];

  // If the default suggester is no longer allowed, use the first allowed
  if (!allowedBasenames.includes(defaultBasename)) {
    defaultBasename = allowedBasenames[0];
  }

  window.localStorage.setItem(LAST_BASENAME_KEY, defaultBasename ?? '');
  window.location.href = `/${defaultBasename}${window.location.pathname}`;
  return [undefined, true];
}
