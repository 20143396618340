import { useLazyCheckUrlSlugQuery } from 'api/ugcRequests';
import Form from 'components/Form';
import { IconCheck, IconLoader, IconWarning } from 'icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  brand?: string;
  requestId?: number;
  value: string;
  onChange: (value: string) => void;
}

export default function UrlSlug({ brand, value, onChange, requestId }: Props) {
  const { t } = useTranslation();
  const [checkUrlSlug, { data, isFetching }] = useLazyCheckUrlSlugQuery();
  const isTaken = !!value && data?.taken;
  const isAvailable = !!value && !!data && !data.taken;
  const debouncedCheck = useDebouncedCallback(checkUrlSlug, 500);

  const error =
    isTaken && !!value && !isFetching
      ? t('ugcRequestBuilder.form.urlSlug.validation.taken')
      : null;

  useEffect(
    () => {
      if (value) debouncedCheck({ value, id: requestId });
      return () => debouncedCheck.flush();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  const handleChange = (value: string) => {
    onChange(value.replace(/[^A-Za-z0-9\-]/gi, '-').substr(0, 40));
  };

  return (
    <Form.Section title={t('ugcRequestBuilder.form.urlSlug.heading')}>
      <Form.TextInput
        name="urlSlug"
        prefix={`${brand}.ugc.photoshelter.com/`}
        label={t('ugcRequestBuilder.form.urlSlug.label')}
        description={t('ugcRequestBuilder.form.urlSlug.description')}
        value={value}
        errors={error}
        onChange={(e) => handleChange(e.target.value)}
        rightIcon={
          isFetching
            ? IconLoader
            : isAvailable
            ? IconCheck
            : isTaken
            ? IconWarning
            : undefined
        }
        rightIconClassNames={
          isFetching
            ? undefined
            : isAvailable
            ? '!text-green'
            : isTaken
            ? '!text-error'
            : undefined
        }
      />
    </Form.Section>
  );
}
