import { useLogInMutation } from 'api/auth';
import isValidContactValue from 'helpers/isValidContactValue';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from './Form';

interface Props {
  initialValues?: { contactValue?: string; password?: string };
  renderError?: (error: string, accountExists: boolean) => React.ReactNode;
}

export default function LogInForm(props: Props) {
  const { initialValues, renderError } = props;
  const { t } = useTranslation();
  const [contactValue, setContactValue] = useState(
    initialValues?.contactValue || ''
  );
  const [password, setPassword] = useState(initialValues?.password || '');
  const [isInvalidContactValue, setIsInvalidContactValue] = useState(false);
  const [logIn, { data, isLoading, error }] = useLogInMutation();

  const handleSubmit = () => {
    setIsInvalidContactValue(false);
    if (isValidContactValue(contactValue)) {
      logIn({ contactValue, password });
    } else {
      setIsInvalidContactValue(true);
    }
  };

  const hasError = !!(data && 'error' in data) || !!error;
  const accountExists = !!data && 'accountExists' in data && data.accountExists;

  return (
    <>
      {hasError &&
        (renderError ? (
          renderError(
            (data && 'error' in data && data.error) || '',
            accountExists
          )
        ) : (
          <div className="notice notice--error">{t('auth.logIn.error')}</div>
        ))}
      <Form onSubmit={handleSubmit}>
        <div className="mb-4">
          <Form.TextInput
            name="contactValue"
            value={contactValue}
            onChange={(e) => setContactValue(e.target.value)}
            label={t('auth.logIn.contactValue.label')}
            placeholder={t('auth.logIn.contactValue.placeholder')}
            errors={
              isInvalidContactValue ? t('global.invalidContactValue') : null
            }
          />

          <Form.TextInput
            name="password"
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label={t('auth.logIn.password.label')}
            placeholder={t('auth.logIn.password.placeholder')}
          />
        </div>

        <button
          className="btn btn--primary mb-2"
          type="submit"
          disabled={isLoading || !contactValue || !password}
          data-loading={isLoading}
        >
          {t('auth.logIn.submit')}
        </button>

        <div className="text-center mt-0.5 text-14">
          <a
            href="https://www.photoshelter.com/usr/usr-password-reset"
            target="_blank"
            className="text-brandPrimary hover:text-brandPrimaryHover underline inline"
            rel="noreferrer"
          >
            {t('auth.logIn.forgotPassword')}
          </a>
        </div>
      </Form>
    </>
  );
}
