import FlyoutMenu from 'components/FlyoutMenu';
import { IconDropdown, IconSort } from 'icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SubmissionFilter from 'types/SubmissionFilter';

interface Props {
  value: SubmissionFilter['sort'];
  onChange: (value: SubmissionFilter['sort']) => void;
}

export default function SubmissionSort({ value, onChange }: Props) {
  const { t } = useTranslation();
  return (
    <FlyoutMenu
      placement="bottom-end"
      renderButton={(onClick, isOpen, ref) => (
        <button
          onClick={onClick}
          ref={ref}
          className="block border border-solid border-grey6 rounded-lg p-1 py-0.5 hover:bg-grey9"
        >
          <span className="flex items-center">
            <IconSort className="text-light w-2 h-2 mr-1" />
            <span className="text-dark text-14 flex-1 mr-2">
              {t(`ugcRequestShow.submissions.sort.${value}`)}
            </span>

            <IconDropdown className="w-2 h-2 text-dark" />
          </span>
        </button>
      )}
    >
      <button onClick={() => onChange('newest')}>
        {t(`ugcRequestShow.submissions.sort.newest`)}
      </button>

      <button onClick={() => onChange('oldest')}>
        {t(`ugcRequestShow.submissions.sort.oldest`)}
      </button>
    </FlyoutMenu>
  );
}
