import api from './index';
import camelize from 'camelize';
import FetchCurrentUserResponse from 'types/FetchCurrentUserResponse';
import UserParams from 'types/UserParams';
import SignUpUserParams from 'types/SignUpUserParams';
import omit from 'lodash/omit';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateProfile: builder.mutation<
      FetchCurrentUserResponse,
      Partial<UserParams>
    >({
      query: (params) => ({ url: 'users', method: 'PUT', body: params }),
      transformResponse: camelize,
    }),

    checkForExistingUser: builder.mutation<
      {
        success: boolean;
      },
      { email: string }
    >({
      query: (params) => ({ url: 'users/hsc', method: 'POST', body: params }),
      transformResponse: camelize,
    }),

    signUpUser: builder.mutation<FetchCurrentUserResponse, SignUpUserParams>({
      query: (params) => ({
        url: 'users',
        method: 'POST',
        body: {
          user: omit(params, 'companyName'),
          companyName: params.companyName,
        },
      }),
      transformResponse: camelize,
      invalidatesTags: ['Plan'],
    }),
  }),
});

export default extendedApi;

export const { useCheckForExistingUserMutation, useSignUpUserMutation } =
  extendedApi;
