import { useLogOutMutation } from 'api/auth';
import User from 'types/User';
import Avatar from 'components/Avatar';
import FlyoutMenu from '../../FlyoutMenu';
import { useTranslation } from 'react-i18next';
import { IconGear, IconPerson, IconSignOut } from 'icons';

interface Props {
  currentUser: User;
}

export default function UserMenu({ currentUser }: Props) {
  const [logOut] = useLogOutMutation();
  const { t } = useTranslation();
  const handleLogOut = async () => {
    await logOut();
    window.location.reload();
  };

  return (
    <FlyoutMenu
      placement="top"
      offset={[0, 8]}
      renderButton={(onClick, isOpen, ref) => (
        <button className="w-4 h-4" onClick={onClick} ref={ref}>
          <Avatar src={currentUser.avatarUrl} name={currentUser.name} />
        </button>
      )}
    >
      <a
        className="flex items-center space-x-0.75"
        href="https://www.photoshelter.com/profile/bio"
        target="_blank"
        rel="noreferrer"
      >
        <IconPerson className="w-2 h-2 text-grey6" />
        <span className="mb-px">{t('globalMenu.userMenu.profile')}</span>
      </a>
      <a
        className="flex items-center space-x-0.75"
        href="https://www.socialie.com/privacy-policy/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconGear className="w-2 h-2 text-grey6" />
        <span className="mb-px">{t('globalMenu.userMenu.privacyPolicy')}</span>
      </a>
      <button onClick={handleLogOut}>
        <span className="flex items-center space-x-0.75">
          <IconSignOut className="w-2 h-2 text-grey6" />
          <span className="mb-px">{t('globalMenu.userMenu.logOut')}</span>
        </span>
      </button>
    </FlyoutMenu>
  );
}
