import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal';
import Form from 'components/Form';
import { useUpdateAccountMutation } from 'api/account';
import useFlashMessages from 'hooks/useFlashMessages';
import useTranslateErrors from 'hooks/useTranslateErrors';

interface Props {
  brand: string;
}

export default function SettingsDomainsBrandRow({ brand }: Props) {
  const { t } = useTranslation();
  const translateErrors = useTranslateErrors();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [value, setValue] = useState(brand);
  const [updateAccount, { isLoading }] = useUpdateAccountMutation();
  const [errors, setErrors] = useState<string[] | null>(null);
  const { addFlashMessage } = useFlashMessages();

  const handleSave = async () => {
    const result = await updateAccount({ brand: value });
    if ('error' in result) {
      if ('data' in result.error && result.error.status === 422) {
        setErrors(result.error.data.errors.brand);
      } else {
        addFlashMessage(t('global.unexpectedError'), {
          isError: true,
        });
      }
    } else {
      addFlashMessage(t('settings.domains.editBrandModal.success'));
      setIsEditModalOpen(false);
    }
  };

  const handleClose = () => {
    setValue(brand);
    setIsEditModalOpen(false);
  };

  return (
    <>
      <div className="pb-4 mb-4 border-b-default last:border-b-0">
        <h4 className="h4 mb-0.5">{t('settings.domains.defaultSubdomain')}</h4>
        <div className="text-14 text-light mb-2">
          {t('settings.domains.defaultSubdomainDescription')}
        </div>

        <a
          className="font-bold text-dark hover:underline"
          href={`https://${brand}.${
            import.meta.env.REACT_APP_FAN_APP_BASE_DOMAIN
          }`}
          target="_"
          rel="nofollow"
        >
          https://{brand}.{import.meta.env.REACT_APP_FAN_APP_BASE_DOMAIN}
        </a>

        <br />

        <button
          className="btn btn--grey btn--small btn--inlineBlock mt-1"
          onClick={() => setIsEditModalOpen(true)}
        >
          {t('global.edit')}
        </button>
      </div>

      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={handleClose}
        renderHeading={t('settings.domains.editBrandModal.heading')}
        renderActions={
          <>
            <button
              className="btn btn--grey btn--inlineBlock"
              onClick={handleClose}
            >
              {t('global.cancel')}
            </button>

            <button
              className="btn btn--primary btn--inlineBlock"
              onClick={handleSave}
              disabled={!value || isLoading}
            >
              {t('global.save')}
            </button>
          </>
        }
      >
        <div className="notice notice--warning">
          {t('settings.domains.editBrandModal.warning')}
        </div>
        <Form.Field errors={translateErrors(errors)}>
          <Form.TextInput
            value={value}
            onChange={(e) => setValue(e.target.value)}
            skipField
          />

          {!errors?.length && (
            <span className="text-14 text-body">
              https://{value}.ugc.photoshelter.com
            </span>
          )}
        </Form.Field>
      </Modal>
    </>
  );
}
