import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import compact from 'lodash/compact';
import submissionApi from 'api/submissions';
import Submission from 'types/Submission';
import { RootState } from 'redux/store';

const submissionsAdapter = createEntityAdapter<Submission>();

const { reducer } = createSlice({
  name: 'submissions',
  initialState: submissionsAdapter.getInitialState({
    metadata: { totalCount: 0, cursor: '' },
  }),
  reducers: {},
  extraReducers: (builder) => {
    // Optimistic update
    builder.addMatcher(
      submissionApi.endpoints.updateSubmission.matchPending,
      (state, action) => {
        const { submission, changes } = action.meta.arg.originalArgs;
        submissionsAdapter.updateOne(state, {
          id: submission.id,
          changes,
        });
      }
    );

    builder.addMatcher(
      submissionApi.endpoints.bulkUpdateSubmissions.matchPending,
      (state, action) => {
        const { submissions, changes } = action.meta.arg.originalArgs;

        submissionsAdapter.updateMany(
          state,
          submissions.map(({ id }) => ({ id, changes }))
        );
      }
    );

    // Rollback optimistic update if update fails
    builder.addMatcher(
      submissionApi.endpoints.updateSubmission.matchRejected,
      (state, action) => {
        const { submission } = action.meta.arg.originalArgs;
        submissionsAdapter.upsertOne(state, submission);
      }
    );

    builder.addMatcher(
      submissionApi.endpoints.bulkUpdateSubmissions.matchRejected,
      (state, action) => {
        const { submissions } = action.meta.arg.originalArgs;
        submissionsAdapter.upsertMany(state, submissions);
      }
    );

    // Fetch pending (reset the list)
    builder.addMatcher(
      submissionApi.endpoints.fetchSubmissions.matchPending,
      (state, action) => {
        if (action.meta.arg.originalArgs.cursor === '') {
          submissionsAdapter.setAll(state, []);
        }
      }
    );

    // Fetch success
    builder.addMatcher(
      submissionApi.endpoints.fetchSubmissions.matchFulfilled,
      (state, action) => {
        const data = action.payload;
        state.metadata = data.metadata;

        // We do this manually because when we fetch individual submissions
        // we add them to entities but not ids and that trips up `upsertMany`
        data.submissions.forEach((fs) => {
          state.entities[fs.id] = { ...state.entities[fs.id], ...fs };
          if (!state.ids.includes(fs.id)) state.ids.push(fs.id);
        });
      }
    );

    builder.addMatcher(
      submissionApi.endpoints.fetchSubmission.matchFulfilled,
      (state, action) => {
        state.entities[action.payload.id] = action.payload;
      }
    );

    // Delete success
    builder.addMatcher(
      submissionApi.endpoints.bulkDeleteSubmissions.matchFulfilled,
      (state, action) => {
        const { submissions } = action.meta.arg.originalArgs;
        state.metadata.totalCount =
          state.metadata.totalCount - submissions.length;
        submissionsAdapter.updateMany(
          state,
          submissions.map(({ id }) => ({ id, changes: { deleted: true } }))
        );
      }
    );
  },
});

export default reducer;

// Selectors
const selectors = submissionsAdapter.getSelectors();

export const getSubmissions = (state: RootState) =>
  selectors.selectAll(state.submissions).filter((f) => !f.deleted);

export const getSubmissionsById = (state: RootState, ids: number[]) => {
  return compact(ids.map((id) => state.submissions.entities[id])).filter(
    (f) => !f.deleted
  );
};

export const getSubmission = (state: RootState, id: number) => {
  return selectors.selectById(state.submissions, id);
};
