import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useSelectableContext } from 'hooks/useSelectable';
import Submission from 'types/Submission';
import SubmissionThumbnail from './SubmissionThumbnail/SubmissionThumbnail';
import Loader from 'components/Loader';
import RenderIfVisible from 'components/RenderIfVisible';
import SubmissionThumbnailContainer from './SubmissionThumbnailContainer';
import UgcRequest from 'types/UgcRequest';

interface Props {
  submissions: Submission[];
  ugcRequest: UgcRequest;
  isFetching: boolean;
  loadNextPage: () => void;
  hasNextPage: boolean;
}

export default function SubmissionList(props: Props) {
  const { submissions, ugcRequest, isFetching, loadNextPage, hasNextPage } =
    props;
  const { selectedIds, toggleSelected } = useSelectableContext();
  const [sentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage,
    onLoadMore: hasNextPage ? loadNextPage : () => {},
    disabled: !submissions.length,
  });

  return (
    <>
      <div className="submission-list">
        {submissions.map((submission) => (
          <RenderIfVisible
            key={submission.id}
            renderPlaceholder={() => (
              <SubmissionThumbnailContainer
                width={submission.width}
                height={submission.height}
              />
            )}
          >
            <SubmissionThumbnail
              ugcRequest={ugcRequest}
              submission={submission}
              isSelectMode={!!selectedIds.length}
              isSelected={selectedIds.includes(submission.id)}
              onToggleSelected={() => toggleSelected(submission.id)}
            />
          </RenderIfVisible>
        ))}
      </div>

      {(hasNextPage || isFetching) && (
        <div ref={sentryRef}>
          <Loader />
        </div>
      )}
    </>
  );
}
