import React, { useState } from 'react';
import { selectCurrentUser } from 'redux/reducers/auth';
import { useAppSelector } from 'redux/store';
import {
  OnboardingBrand,
  OnboardingIntro,
  OnboardingStyles,
} from './components';

const steps = ['intro', 'brand', 'styles'] as const;
type Step = typeof steps[number];

export default function Onboarding() {
  const account = useAppSelector(selectCurrentUser)?.currentAccount;

  const [step, setStep] = useState<Step>(steps[0]);

  if (!account || account.onboardingCompleted) return null;

  const components: { [key in Step]: JSX.Element } = {
    intro: <OnboardingIntro onContinue={() => setStep('brand')} />,
    brand: (
      <OnboardingBrand account={account} onContinue={() => setStep('styles')} />
    ),
    styles: <OnboardingStyles />,
  };

  return components[step];
}
