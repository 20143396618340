import { useBulkDeleteSubmissionsMutation } from 'api/submissions';
import useFlashMessages from 'hooks/useFlashMessages';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import UgcRequest from 'types/UgcRequest';
import Submission from 'types/Submission';
import Modal from './Modal';

interface Props {
  ugcRequest: UgcRequest;
  submissions: Submission[];
  isOpen: boolean;
  onRequestClose: () => void;
  onAfterDelete?: () => void;
}

export default function SubmissionDeleteModal(props: Props) {
  const isMounted = useRef(true);
  const { addFlashMessage } = useFlashMessages();
  const [bulkDeleteSubmissions, { isLoading }] =
    useBulkDeleteSubmissionsMutation();
  const { isOpen, onRequestClose, submissions, ugcRequest, onAfterDelete } =
    props;
  const { t } = useTranslation();
  const count = submissions.length;

  const handleDelete = async () => {
    const result = await bulkDeleteSubmissions({
      submissions,
      ugcRequestId: ugcRequest.id,
    });

    if ('data' in result) {
      addFlashMessage(t('submissionDeleteModal.success', { count }));
      onAfterDelete?.();
      if (isMounted.current) onRequestClose();
    } else {
      addFlashMessage(t('global.unexpectedError'), { isError: true });
    }
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      renderHeading={t('submissionDeleteModal.heading', { count })}
      renderActions={
        <>
          <button
            className="btn btn--grey btn--inlineBlock"
            onClick={onRequestClose}
          >
            {t('global.cancel')}
          </button>

          <button
            className="btn btn--primary btn--inlineBlock"
            disabled={isLoading}
            data-loading={isLoading}
            onClick={handleDelete}
          >
            {t('submissionDeleteModal.submit', { count })}
          </button>
        </>
      }
    >
      {t('submissionDeleteModal.description', { count })}
    </Modal>
  );
}
